const roadmap = [
    {
        id: 1,
        data: "Our classes are conducted using online video conferencing platform"
    },
    {
        id: 2,
        data: "The length of the course varies from 15-20 sessions based on the child's requirements."
    },
    {
        id: 3,
        data: "The first session is on the house!"
    },
    {
        id: 4,
        data: "We implement Inquiry-based learning to emphasize the child's role in learning and encourage them to ask more questions and approach the topic their own way. This method ensures that the child is heard and we only move on when a full understanding of the particular concept is guaranteed."
    },
    {
        id: 5,
        data: "An assignment that intrigues the child is given after each class and also prepares them to be excited about the next session. This assignment is designed to make the child refresh their memory about their class and test their ability to apply what was taught."
    },
    {
        id: 6,
        data: "Fun projects are also assigned at regular intervals to assess their cognitive abilities. These projects allow us to acknowledge the child's progress in addition to giving them the opportunity to use their creativity."
    },
    {
        id: 7,
        data: " We are very excited about our small community of intellectual coders who come together to share and boost their love for their courses by participating in community-building activities."
    },
    {
        id: 8,
        data: "Team projects are devised specifically for kids according to their progress. The children learn and benefit from each other and this also builds their teamwork."
    },
    {
        id: 9,
        data: "The kids are challenged with live quizzes and tests throughout the course to keep them on their toes and enhance their understanding."
    },
    {
        id: 10,
        data: "The kids finish the course with a big bang! A final project that will showcase what they have learnt and allow us to appreciate their inventiveness."
    },

]


export default roadmap