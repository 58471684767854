import React, {useState} from 'react'
import { Row, Col } from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import {Link} from 'react-router-dom'
import './Footer.css'
import emailjs from 'emailjs-com';

import logo from '../../assets/images/logo.png'

const Footer = () => {

    const [submit, setSubmit] = useState(false)

    const [inputValue, setInputValue] = useState({
        name: "",
        email: "",
    })

    const handleChange = (e) => {
        setInputValue({
            ...inputValue,
            [e.target.name] : e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmit(true)
        e.target.reset()
        await emailjs.send('service_wzdrsta', 'template_2tl0szt', inputValue, 'user_QlcZdPgBVSXfVyf4nVitP')
        // emailjs.send('service_wzdrsta', 'template_2tl0szt', inputValue, 'user_QlcZdPgBVSXfVyf4nVitP')
        //   .then(function(response) {
        //     console.log('SUCCESS!', response.status, response.text);
        //     setSubmit(!submit);
        //   }, function(error) {
        //     console.log('FAILED...', error);
        //   });
        setSubmit(false)
    }


    return (
        <div className="footer">
            <div className="enquiry">
                <div className="enquiry-container">
                    <h1>You have the power to define your future.</h1>
                    <div className="enquiry-container-form">
                        <form onSubmit={handleSubmit}>
                            <input onChange={handleChange} className="enquiry-form-input" type="text" placeholder="Enter Name" required="true" name="name"  />
                            <input onChange={handleChange} className="enquiry-form-input" type="email" placeholder="Enter Email"  required="true" name="email" />
                            {
                                submit ? <button className="enquiry-form-btn-sent" type="submit">SENT</button> 
                                        : <button className="enquiry-form-btn" type="submit">SUBMIT</button>
                            }
                        </form>
                    </div>
                </div>
            </div>
            <div className="footer-container">
                <Row>
                    <Col md={7} className="footer-col1">
                        <img src={logo} alt="brand" />
                        <h4>Enlightening the future of the world</h4>
                        <h4>with the language of the future</h4>
                        <div className="footer-contact">
                            <h6><i class="fas fa-envelope"></i> <a href="mailto: customersupport@codevirtua.com"> customersupport@codevirtua.com</a></h6>
                            <h6><i class="fas fa-phone"></i> <a href="tel:+919488308516">+91 9488308516</a></h6>
                        </div>
                    </Col>
                    <Col md={3} className="footer-col-heading">
                        <h6 className="footer-title">ONLINE COURSES</h6>
                        <Link to="/courses" className="course-links" ><h6 className="ft-links">Python</h6></Link>
                        <Link to="/courses" className="course-links" ><h6 className="ft-links">C Programming</h6></Link>
                        <Link to="/courses" className="course-links" ><h6 className="ft-links">C++ Programming</h6></Link>
                        <Link to="/courses" className="course-links" ><h6 className="ft-links">Web Development</h6></Link>
                        <Link to="/courses" className="course-links" ><h6 className="ft-links">Java Programming</h6></Link>
                        <Link to="/courses" className="course-links" ><h6 className="ft-links">Scratch Programming</h6></Link>
                        
                        
                    </Col>
                    <Col md={2} className="footer-col-heading">
                        <h6 className="footer-title">COMPANY</h6>
                        <LinkContainer to='/about'><h6 className="ft-links">About Us</h6></LinkContainer>
                        <LinkContainer to='/faq'><h6 className="ft-links">FAQ</h6></LinkContainer>
                        <LinkContainer to='/contact'><h6 className="ft-links">Contact Us</h6></LinkContainer>
                    </Col>
                </Row>
                <Row>
                    <Col xs={5} sm={2} md={2} >
                        <h6 className="footer-tp"><a href="/">Terms of Use</a></h6>
                    </Col>
                    <Col xs={5} sm={2} md={2}>
                        <h6 className="footer-tp"><a href="/privacy">Privacy Policy</a></h6>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer
