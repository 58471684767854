import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import theme from './theme'
import './CourseTimeline.css'

export default function CourseTimeline({curr, number, color:{hex}}) {

  const useStyles = makeStyles((theme) => ({

    paper: {
      background: hex,
      padding: '6px 16px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
  }));


  const classes = useStyles();

  const sliced = curr.slice(0, number);

  return (
      <ThemeProvider theme={theme}>
      <Timeline align="left">
          {
              sliced.map(e => {
                  return (
                      <TimelineItem>
                          <TimelineSeparator>
                          <TimelineDot  color={"secondary"}> 
                          </TimelineDot>
                          <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                          <Paper elevation={3} className={classes.paper}>
                              
                                  <Typography className="ct-title" variant="h6">
                                  {e.title}
                                  </Typography>
                                  <Typography>{e.desc}</Typography>
                    
                          </Paper>
                          </TimelineContent>
                      </TimelineItem>
                  )
              })
          }
      </Timeline>
      </ThemeProvider>
  );
}