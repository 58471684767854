import React,{useState} from 'react'
import './CourseScreen.css'
import courses from '../../data/courses'
import roadmap from '../../data/roadmap'
import CourseTimeline from '../../components/CourseTimeline/CourseTimeline'
import CourseScreenCard from '../../components/CourseScreenCard/CourseScreenCard'
import CourseCard from '../../components/CourseCard/CourseCard'
import { Container, Row, Col } from 'react-bootstrap'
import {Link} from "react-scroll"


const CourseScreen = ({match}) => {

        const course = courses.find(p => p.id === match.params.id)
        const {color:{name,hex}, highlights, curriculum} = course;

        const [section, setSection] = useState(false);

        const handleShow = () => {
            setSection(!section);
        }



    return (
        <div className="course-screen">
            <div className="course-screen-hero">
                <Container>
                <Row>
                    <Col md={8}>
                        <h1>{course.title}</h1>
                        <h5>{course.difficulty}</h5>
                        <p>{course.descS}{course.descF}</p>
                        <Link to="cscscroll" smooth={true} offset={-200}>
                            <button className={`csb-temp-btn`}>Register <i class="fas fa-arrow-circle-right"></i></button>
                        </Link>
                    </Col>
                    <Col md={4}></Col>
                </Row>
                
                </Container>
            </div>
            <div className="course-screen-body">
                <Container>
                <Row>
                    <Col md={8}>
                        <div style={{backgroundColor: hex}} className="csb-highlights">
                            <h4>CodeVirtuan's Perks</h4>
                            <Row className="csb-highlights-row">
                                {
                                    highlights.map(e => {
                                        return(
                                            <Col className="csb-perks-col" sm={6} md={6} lg={6}>
                                            <h6><i class="far fa-check-circle"></i> {e.text}</h6>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                        <div className="csb-curriculum">
                            <h4>Course Curriculum</h4>
                                <CourseTimeline color={course.color} curr={course.curriculum} number={section ? curriculum.length : 3 } />
                                <div className="csb-showMore">
                                    <button className={name} onClick={handleShow}>{section ? "Show Less" : "Show More"}</button>
                                </div>
                        </div>
                        <div className="csb-pre">
                            <Container>
                                <h4>Prerequisites</h4>
                                <h6>{course.pre} <i class={course.emoji}></i></h6>
                            </Container>
                        </div>
                        <div className="csb-course">
                            <Container>
                                <h4>Course Overview</h4>
                                <ul>
                                    {
                                        roadmap.map(e => {
                                            return(
                                                <li><p>{e.data}</p></li>
                                            )
                                        })
                                    }
                                </ul>
                            </Container>
                        </div>
                        
                    </Col>
                    <Col md={4}>
                            <div id="cscscroll"></div>
                            <CourseScreenCard course={course} />
                            
                    </Col>
                </Row>
                </Container>
            </div>
            <div className="csb-like">
                <Container>
                    <h4>You May Also like</h4>
                    <Row>
                        {
                            courses.filter(e => course.title !== e.title ).slice(0,5).map(element =>{
                                    return(
                                        <Col sm={12} md={6} lg={4} className="csb-like-col" >
                                            <CourseCard 
                                                key={element.id}
                                                image={element.img}
                                                title={element.title}
                                                descS={element.descS}
                                                descF={element.descF}
                                                difficulty={element.difficulty}
                                                id={element.id}
                                                />
                                        </Col>
                                    )
                                })
                        }
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CourseScreen