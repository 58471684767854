import React from 'react'

import HomeHero from './sections/HomeScreenSections/HomeHero/HomeHero'
import MissionSection from './sections/HomeScreenSections/MissionSection/MissionSection'
import HowItWorks from './sections/HomeScreenSections/HowItWorks/HowItWorks'
import WhyCode from './sections/HomeScreenSections/WhyCode/WhyCode'
import Courses from './sections/HomeScreenSections/Courses/Courses'
import Enquiry from './sections/HomeScreenSections/Enquiry/Enquiry'
import HowDiff from './sections/HomeScreenSections/HowDiff/HowDiff'
import Testimonial from './sections/HomeScreenSections/Testimonial/Testimonial'

const HomeScreen = () => {

    return (
            <div class="homescreen">
                <HomeHero />
                <MissionSection />
                <WhyCode />
                <HowItWorks />
                <HowDiff />
                <section id="coursescroll"></section>
                <Courses />
                <Testimonial />
             </div>
    )
}

export default HomeScreen
