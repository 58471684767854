import React,{useState} from 'react'
import './CourseScreenCard.css'
import CourseModal from '../CourseModal/CourseModal'

const CourseScreenCard = ({course}) => {

    const {img} = course

    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="csc">
            <img src={img} alt="course" />
            <div className="csc-body">
                <button onClick={() => setModalShow(true)} className="cc-button">Register <i class="fas fa-arrow-circle-right"></i></button>
                <p className="free">for the first <span>FREE</span> class</p>
            </div>
            <CourseModal show={modalShow} onHide={()=> setModalShow(false)} />
        </div>
    )
}

export default CourseScreenCard
