import add from '../assets/images/icons/add.png'
import calendar from '../assets/images/icons/calendar.png'
import card from '../assets/images/icons/card.png'
import trophy from '../assets/images/icons/trophy.png'
import dialog from '../assets/images/icons/dialog.png'
import target from '../assets/images/icons/target.png'
import memo from '../assets/images/icons/memo.png'
import board from '../assets/images/3dElements/Saly-board.png'
import rocket from '../assets/images/Saly/rocket.png'

import stress from '../assets/images/memoji/memo-stress.png'
import courage from '../assets/images/memoji/memo-courage.png'
import excited from '../assets/images/memoji/memo-excited.png'
import happy from '../assets/images/memoji/memo-happy.png'
import love from '../assets/images/memoji/memo-love.png'
import think from '../assets/images/memoji/memo-think.png'
import logical from '../assets/images/memoji/memo-logical.png'
import critical from '../assets/images/memoji/memo-critical.png'
import future from '../assets/images/memoji/memo-future.png'

 
 
 const Working = [
    {
        id : 1,
        title: 'Select Course',
        desc: ' Browse through the array of courses we offer and choose what piques your interest.',
        img : add
    },
    {
        id : 2,
        title: 'Register',
        desc: ' Enroll in your selected course for a reasonable fee',
        img : board
    },
    {
        id : 3,
        title: 'Hear from us',
        desc: ' Receive a mail from us regarding your first demo class',
        img : target
    },
    {
        id : 4,
        title: 'We reach out',
        desc: 'Schedule your first free class with us on call.',
        img : dialog
    },
    {
        id : 5,
        title: 'Experience the excitement',
        desc: 'Get a taste of your selected course before you commit',
        img : memo
    },
    {
        id : 6,
        title: 'Lock your Schedule',
        desc: 'Confirm your schedule according to your leisure after you have loved your first class ',
        img : calendar
    },
    {
        id : 7,
        title: 'Pay monthly',
        desc: ' Avoid long-term commitments and pay on a monthly basis if the classes are satisfactory.',
        img : card
    },
    {
        id : 8,
        title: 'You’re a coder!',
        desc: 'Complete your course at your own pace and celebrate because now you’re a coder!',
        img : trophy
    },
    {
        id : 9,
        title: 'Want more?',
        desc: 'Jump into the next course with 20% off because we know you can never get enough of coding',
        img : rocket
    },

]



const WhyCoding = [
    {
        id : 1,
        title : 'Language of the Future',
        icon : 'wc-i fas fa-rocket',
        bg : 'green',
        img: future
    },
    {
        id : 2,
        title : 'Logical Thinking',
        icon : 'wc-i fas fa-chart-line',
        bg : 'yellow',
        img: logical
    },
    {
        id : 3,
        title : 'Critical Thinking',
        icon : 'wc-i fas fa-bullhorn',
        bg : 'red',
        img: critical
    },
    {
        id : 4,
        title : 'Problem-Solving Skills',
        icon : 'wc-i fas fa-cubes',
        bg : 'beige',
        img: love
    },
    {
        id : 5,
        title : 'Processing Skills',
        icon : 'wc-i fas fa-brain',
        bg : 'blue',
        img: think
    },
    {
        id : 6,
        title : 'Courage',
        icon : 'wc-i fas fa-gem',
        bg : 'pink',
        img: courage
    },
    {
        id : 7,
        title : 'No pressure',
        icon : 'wc-i fas fa-rocket',
        bg : 'orange',
        img: stress
    },
    {
        id : 8,
        title : 'Productive',
        icon : 'wc-i fas fa-shapes',
        bg : 'purple',
        img: happy
    },
    {   
        id : 9,
        title : 'Fun and Exciting',
        icon : 'wc-i fas fa-hand-spock',
        bg : 'red',
        img: excited
    },
]


const HowUnique = [
    {
        id: 1,
        title: 'Respect the Craft!',
        desc: 'We possess an infectious passion for coding and we will imprint this love for the subject on your child.',
        bg: 'hd-card red'
    },
    {
        id: 2,
        title: 'Fun and Games!',
        desc: ' Fun and interactive method of teaching to bring out an organic love for the art of coding. ',
        bg: 'hd-card sgreen'
    },
    {
        id: 3,
        title: 'Flexible Curriculum',
        desc: 'A syllabus tailored according to your requirements and strengths.',
        bg: 'hd-card blue'
    },
    {
        id: 4,
        title: 'One on One Tutoring',
        desc: 'An exclusive experience of learning and guidance  every step of the way.',
        bg: 'hd-card yellow'
    },
    {
        id: 5,
        title: 'Parental Inclusion',
        desc: 'Constant updates to parents on any headway that the child makes and always ready to listen to feedback.',
        bg: 'hd-card yellow'
    },
    {
        id: 6,
        title: 'Pay as you Go',
        desc: 'Monthly payments with room for discontinuing if not satisfied.',
        bg: 'hd-card blue'
    },
    {
        id: 7,
        title: 'Accommodating Schedule',
        desc: 'Pick your dates and design your schedule according to your preferences.',
        bg: 'hd-card red'
    },
    {
        id: 8,
        title: 'Beyond the Classroom',
        desc: 'Experiment and explore outside the classroom with your specially assigned  projects and assignments.',
        bg: 'hd-card sgreen'
        
    },
    {
        id: 9,
        title: "CodeVirtua Community",
        desc: 'Interact with fellow coders and expand your knowledge.',
        bg: 'hd-card blue'
    },
    {
        id: 10,
        title: 'Awards for the Stars',
        desc: 'Your accomplishments don’t go unnoticed! Validate your efforts by winning one of the many awards at our Annual Award ceremony.',
        bg: 'hd-card sgreen'
    },
    {
        id : 11,
        title: 'Help us Help',
        bg: 'hd-card yellow'
    }
]


export {Working, WhyCoding, HowUnique}