import React from 'react'
import './HowDiff.css'
import {Row, Col, Container} from 'react-bootstrap'
import {HowUnique} from '../../../../data/data'

const HowDiff = () => {
    return (
        <div className="hd">
            <Container>
                <h1>How are we Unique?</h1>
                <Row className="hd-row">
                    {
                        HowUnique.map(e => {
                            return(
                                <Col sm={12} md={3} className="hd-col"> 
                                    <div className={e.bg}>
                                        <h5 className="hd-card-title">{e.title}</h5>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default HowDiff
