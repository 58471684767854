import React from 'react'
import './HomeHero.css'
import image from '../../../../assets/images/Saly-10.png'
import {Row, Container, Col} from 'react-bootstrap'
import {Link} from "react-scroll"


const HomeHero = () => {
    return (
        <div className="hs-hero-wrap">
                    <Container>
                    <Row className="hs-hero">
                        <Col sm={12} md={6} className="hs-hero-1">
                            <h1 className="hs-title">CodeVirtua.</h1>
                            <p>CodeVirtua is a learning platform that provides an array of One on One coding courses like Python, Java, JavaScript, etc. Our customized courses are tailor-made to suit each unique individual and bring out the best in them by incorporating fun-loving, interactive, authentic, and passion-driven methods of learning.</p>
                            <Link to="coursescroll" smooth>
                                <button className="hs-hero-btn">Browse Courses &nbsp;<i class="fas fa-arrow-right"></i></button>
                            </Link>
                        </Col>
                        <Col sm={12} md={6} className="hs-hero-2">
                            <img className="hs-hero-img" src={image} alt="img" />
                        </Col>
                    </Row>
                    </Container>
        </div>
    )
}

export default HomeHero
