import React, {useState} from 'react'
import './Courses.css'
import CourseCard from '../../../../components/CourseCard/CourseCard'
import board from '../../../../assets/images/3dElements/Saly-board.png' 
import {Container, Col, Row} from 'react-bootstrap'
import courses from '../../../../data/courses'
import { HashLink } from 'react-router-hash-link';

const Courses = () => {

    const [topic, setTopic] = useState("all");

    return (
        <div id="courses" className="hs-course" >
                    <Container>
                        <div className="hs-course-title">
                        <div>
                            <h4>Trending Courses</h4> <span><img src={board} alt="" /></span>
                        </div> 
                        </div>
                        <h6>CATEGORIES</h6>
                        <div className="hs-course-category">
                            <button className={topic==="all"? "cat-btn-active": "cat-btn"} onClick={ () => setTopic("all") }>All</button>
                            <button className={topic==="webdev"? "cat-btn-active": "cat-btn"} onClick={ () => setTopic("webdev") }>Web development</button>
                            <button className={topic==="python"? "cat-btn-active": "cat-btn"} onClick={ () => setTopic("python") }>Python</button>
                            <button className={topic==="C++"? "cat-btn-active": "cat-btn"} onClick={ () => setTopic("C++") }>C++</button>
                            <button className={topic==="C"? "cat-btn-active": "cat-btn"} onClick={ () => setTopic("C") }>C</button>
                            <button className={topic==="Scratch"? "cat-btn-active": "cat-btn"} onClick={ () => setTopic("Scratch") }>Scratch</button>
                            <button className={topic==="Java"? "cat-btn-active": "cat-btn"} onClick={ () => setTopic("Java") }>Java</button>
                            
                        </div>
                    <Row>
                        {   
                            topic==="all" ? (courses.map(element => {
                                return(
                                    <Col sm={12} md={6} lg={4} className="hs-course-row" >
                                            <CourseCard 
                                                key={element.id}
                                                image={element.img}
                                                title={element.title}
                                                descS={element.descS}
                                                descF={element.descF}
                                                difficulty={element.difficulty}
                                                id={element.id}
                                                />
                                        </Col>
                                )
                            })) : (
                                courses.filter(e => e.category ===topic).map(element =>{
                                    return(
                                        <Col sm={12} md={6} lg={4} className="hs-course-row" >
                                            <CourseCard 
                                                key={element.id}
                                                image={element.img}
                                                title={element.title}
                                                descS={element.descS}
                                                descF={element.descF}
                                                difficulty={element.difficulty}
                                                id={element.id}
                                                />
                                        </Col>
                                    )
                                })
                            )
                                
                        }
                    </Row>
                    </Container>
        </div>
    )
}

export default Courses
