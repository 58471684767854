import React,{useState} from 'react'
import CourseModal from '../CourseModal/CourseModal'
import './CourseCard.css'
import {Link} from 'react-router-dom'

const CourseCard = ({image, title, descS,desF, difficulty, key, id}) => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="cc">
            <div className="cc-head">
                <img src={image} alt="img" />
            </div>
            <div className="cc-body">
                <h5>{title}</h5>
                <h6>{difficulty}</h6>
                <p>{descS}...</p>
                <div className="cc-btn">
                    <button onClick={() => setModalShow(true)} className="cc-button">Enrol <i class="fas fa-arrow-circle-right"></i></button>
                    <button className="cc-button">
                        <Link className="link" to={`/course/${id}`}>Know More <i class="fas fa-info-circle"></i></Link>
                    </button>
                </div>
            </div>
            <CourseModal title={title} difficulty={difficulty} show={modalShow} onHide={()=> setModalShow(false)} />
        </div>
    )
}

export default CourseCard
