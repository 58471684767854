import React,{useState} from 'react'
import './CourseModal.css'
import {Modal, Button, Form} from 'react-bootstrap'
import calendar from '../../assets/images/3dElements/Saly-calendar.png'
import countryCode from '../../data/countryCode'
import emailjs from 'emailjs-com'
import { LinkContainer } from 'react-router-bootstrap'
import {Alert} from 'react-bootstrap'
import 'react-phone-input-2/lib/bootstrap.css'
import PhoneInput from 'react-phone-input-2'


const CourseModal = (props) => {

    const [mobile, setMobile] = useState("");
    const [sent, setSent] = useState(false);
    const [validMobile, setValidMobile] = useState(false);

    const [inputValue, setInputValue] = useState({
      courseName: props.title,
      difficulty: props.difficulty,
      number: null,
      pname: "",
      sname: "",
      age: Number,
      email: "",
      extension: ""
    })

    const handleChange = (e) => {
        setInputValue({
          ...inputValue,
          [e.target.name] : e.target.value
        })
    }

    // const handleOnChange = (value, country) => {
    //   setMobile({rawnumber: value.slice(country.dialCode.length) })
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setValidMobile(false);
        
          if(mobile.length > 10){
            const mob = mobile.slice(0,-10);

            inputValue.extension = mob;
            inputValue.number = mobile;

            console.log(inputValue);

            try {
              setSent(true);
              const response = await emailjs.send('service_hmoikmc', 'template_u97tgxi', inputValue, 'user_QlcZdPgBVSXfVyf4nVitP')
              console.log(response);

            } catch (error) {
              console.log("fail")
            }
            // emailjs.send('service_hmoikmc', 'template_u97tgxi', inputValue, 'user_QlcZdPgBVSXfVyf4nVitP')
            // .then(function(response) {
            //   console.log('SUCCESS!', response.status, response.text);
            // }, function(error) {
            //   console.log('FAILED...', error);
            // });

            props.onHide();
          }else{
              setValidMobile(true)
          }

  }



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered

            dialogClassName="cmodal"
        >
      <Modal.Header closeButton>
        <Modal.Title class="cmodal-title" id="contained-modal-title-vcenter">
          Enrol<span><img src={calendar} alt="calendar" /></span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="cmodal-content">
      {
        sent ? <Alert variant="success">Sent!</Alert> : ""
      }
      {
        validMobile ? <Alert variant="danger">Enter Valid Contact Number</Alert> : "" 
      }
        <Form onSubmit={handleSubmit}>
            <Form.Group className="cmodal-group" controlId="PName">
                  <span><i class="fas fa-user-friends"></i></span><Form.Control onChange={handleChange}  onKeyPress={event => (event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122)} value={inputValue.pname} className="cmodal-input" type="text" required="true" placeholder="Enter Parent Name" name="pname" />
            </Form.Group>
            <div className="cmodal-name">
                <Form.Group className="cmodal-group mr-3" controlId="SName">
                  <span><i class="fas fa-user-graduate"></i></span><Form.Control onChange={handleChange} value={inputValue.sname} className="cmodal-input" type="text" required="true" placeholder="Enter Student Name" name="sname" />
                </Form.Group>
                <Form.Group className="cmodal-group" controlId="Age">
               <span><i class="fas fa-shapes"></i></span><Form.Control onChange={handleChange} value={inputValue.name} className="cmodal-input" type="number" min={1} required="true" placeholder="Enter Student's Age" name="age"/>
                </Form.Group>
            </div>
            <Form.Group className="cmodal-group" controlId="formGroupEmail">
               <span><i class="fas fa-envelope"></i></span><Form.Control onChange={handleChange} value={inputValue.email} className="cmodal-input" type="email" required="true" placeholder="Enter Email" name="email" />
            </Form.Group>
            <h6 className="c-warning">Enter your Parent's email if you are below 18 years of age</h6>
            <PhoneInput value={mobile} onChange={setMobile} preferredCountries={['us', 'gb', 'in', 'ae']} />
            <div className="cmodal-declarations">
              <div className="cmodal-check-group">
                <input className="cmodal-check" type="checkbox" id="age" required /><label for="age" className="cmodal-check-label">I hereby confirm that if I'm below 18, I have given my Parent/Guardian's email address</label>
              </div>
              <div className="cmodal-check-group">
                <input className="cmodal-check" type="checkbox" id="terms" required /><label for="terms" className="cmodal-check-label">I hereby confirm that I have read the <a href="/privacy">Privacy Policy</a> and the <a href="#">Terms of Use</a>.</label>
              </div>
              <Button className="cmodal-btn" type="submit">Submit &nbsp;<i class="fas fa-paper-plane"></i></Button>
            </div>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>submit</Button>}
      </Modal.Footer> */}
    </Modal>
    )
}

export default CourseModal
