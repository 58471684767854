import React from 'react'
import './HowItWorks.css'
import styles from './HowItWorks.module.css'
import {Container} from 'react-bootstrap'
import SliderCard from '../../../../components/SliderCard/SliderCard'
import element from '../../../../assets/images/Saly/round.png'

import {Working} from '../../../../data/data'

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const HowItWorks = () => {
    return (
        <div className="hws">
            <Container><h1>How <span className="hws-brand">CodeVirtua</span> works?</h1></Container>
            <Swiper
                spaceBetween={30}
                breakpoints={{
                    // when window width is >= 640px
                    640: {
                    width: 640,
                    slidesPerView: 2,
                    },
                    // when window width is >= 768px
                    768: {
                    width: 768,
                    slidesPerView: 3,
                    },
                    // when window width is >= 992px
                    992: {
                    width: 992,
                    slidesPerView: 4
                    }
                }}
                autoplay={{delay: 3000}}
                navigation={true}
                className={styles.swiperContainer}
                >
                
                {
                    Working.map(element => {
                        return(
                            <SwiperSlide key={element.id} className="swiper">
                                <SliderCard title={element.title} desc={element.desc} img={element.img} />
                            </SwiperSlide>
                        )
                    })
                }
    
                ...
            </Swiper>
        </div>
    )
}

export default HowItWorks
