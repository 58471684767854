const reviews = [
    {
        id:1,
        name: '-Bhuvana Selvakumar, Parent of Sai Krishna & Hari Krishna',
        desc: "The tutor gives a very detailed explanation of each topic and makes everything understandable. Clears my child's doubts instantly. Kids are introduced to new interesting concepts making the child curious and more eager to learn. The topics taught are very relevant to the current scenario.",
        loc: 'Chidambaram, India'
    },
    {
        id:2,
        name: '-Siva Rajarajacholan',
        desc: "Well prepared and engaging tutor.  I appreciate the teaching style and the syllabus. She makes sure that the child understands everything and if not, explains everything patiently again. My child has learnt a lot from this and it will surely benefit others too.",
        loc: 'Wisconsin, USA'
    },
    {
        id:3,
        name: '-Suven Ponduri (8 yrs)',
        desc: "Before taking the class all I knew was variables and Printing statements. After your class I know many things like defining functions and if statements. I really liked your class because many of the projects were really fun and you're very friendly and helpful. P.S - looking forward to learn new things. Thank you for being my teacher,",
        loc: 'Pennsylvania, USA'
    },
    {
        id:4,
        name: "-Kokila Rajesh, Parent of Ajay & Sanjay",
        desc: ' "The tutor gives undivided attention to my child. The method of teaching is very detailed and the course is cost effective." ',
        loc: 'Pennsylvania, USA'
    },
    {
        id:5,
        name: "-Jayashree Gunaseelan, Parent of Varsitaa and Hritik",
        desc: "My kids who are 15yrs old and 11 yrs old took Python programming classes with Dhivya for 2 months during the 2020 summer season. They enjoyed learning the programming and were able to easily grasp the concepts and the credit goes to Dhivya. Dhivya's dedication and commitment in her teaching is extraordinary. She always makes sure the kids understand the concept clearly by asking questions in the class and giving weekly assignments. I would love to continue with her and recommend her to my friends.",
        loc: 'Pennsylvania, USA'
    },
    {
        id:6,
        name: '-Ashwin and Aswath, Undergrads',
        desc: "I am a beginner in coding.I had some difficulties and when I found this platform it helped me solve all my doubts. and my Instructor gave assignments which were very helpful.I have learned a lot!",
        loc: 'Coimbatore, India'
    },
]

export default reviews