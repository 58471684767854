import React from 'react'
import './WhyCode.css'
import {Container, Row, Col} from 'react-bootstrap'

import {WhyCoding} from '../../../../data/data'

const WhyCode = () => {
    return (
        <div className="wc">
            <Container>
            <h1>Why Coding ? 🧊</h1>
            <Row className="wc-row">
            {
                WhyCoding.map(element => {
                    return (
                        <Col className="wc-card" sm={12} md={3}>
                            <div key={element.id} className={element.bg}>
                                <span><i class={element.icon}></i></span>
                                <h4>{element.title}</h4>
                            </div>
                        </Col>
                    )
                })
            }
            </Row>
            </Container>
        </div>
    )
}

export default WhyCode
