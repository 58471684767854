import React from 'react'
import FaqHero from './sections/FaqSections/FaqHero/FaqHero'
import FaqBody from './sections/FaqSections/FaqBody/FaqBody'

const FaqScreen = () => {
    return (
        <div>
            <FaqHero />
            <FaqBody />
        </div>
    )
}

export default FaqScreen
