import React from 'react'
import ContactHero from './sections/ContactSections/ContactHero/ContactHero'

const ContactScreen = () => {
    return (
        <div className="contact-screen">
                <ContactHero />
        </div>
    )
}

export default ContactScreen
