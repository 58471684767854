import React,{useState} from 'react'
import './ContactHero.css'
import {Container, Row, Col, Form} from 'react-bootstrap'
import image from '../../../../assets/images/Saly/Saly-1.png'

import emailjs from 'emailjs-com';

const ContactHero = () => {

    const [inputValue, setInputValue] = useState({
        name : "",
        email: "",
        message: "",
    })

    const [submit, setSubmit] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.send('service_wzdrsta', 'template_2tl0szt', inputValue, 'user_QlcZdPgBVSXfVyf4nVitP')
          .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
            setSubmit(!submit);
          }, function(error) {
            console.log('FAILED...', error);
          });

          e.target.reset();
        }
        

    const handleChange = (e) => {
        console.log(e.target.value);
        setInputValue({
            ...inputValue,
            [e.target.name] : e.target.value
        })
    }
    

    return (
        <div className="contact-hero">
            <Container>
                <Row>
                    <Col className="contact-hero-1" sm={12} md={6}>
                        <h1 className="contact-hero-title">Contact Us</h1>
                        <div className="contact-hero-form">
                            <div className="ch-form-wrapper">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label className="contact-hero-label">Name</Form.Label>
                                     <Form.Control onChange={handleChange} className="contact-hero-input" type="text" name="name" required="true" />
                                </Form.Group>
                                <Form.Group>
                                     <Form.Label className="contact-hero-label">Email address</Form.Label>
                                    <Form.Control onChange={handleChange}  className="contact-hero-input" type="email" name="email" required="true"  />
                                </Form.Group>
                                <Form.Group>
                                     <Form.Label className="contact-hero-label">Message</Form.Label>
                                    <Form.Control onChange={handleChange} className="contact-hero-input" as="textarea" rows={5} name="message" required="true" />
                                </Form.Group>
                                {
                                    submit ? (<button className="contact-hero-btn-sent" type="submit">Sent <i class="fas fa-check-circle"></i></button>)
                                    : (<button className="contact-hero-btn" type="submit">Submit</button>)
                                }
                                
                            </Form>
                            </div>
                        </div>
                    </Col>
                    <Col className="contact-hero-2" sm={12} md={6}>
                        <img className="contact-hero-img" src={image} alt="rocket" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactHero
