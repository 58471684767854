import React from 'react'
import './SliderCard.css'


const SliderCard = ({title, desc, img}) => {
    return (
        <div className="sliderCard">
            <h4>{title}</h4>
            <img src={img} alt="icon" />
            <p>{desc}</p>
        </div>
    )
}

export default SliderCard
