const faqs = [
    {
        id : 1,
        question : 'How is your platform unique from the others?',
        answer: 'The curriculum is more comprehensive and structured than any other online coding program available.The program combines the benefits of teacher-led instruction, a students-first approach, and hands-on, project-based learning, with the convenience of the online classroom. It’s the most effective way for your child to learn to code. We are a small company of undergrads always eager and available to listen to your requirements unlike large platforms who are unable to personally be in touch with their huge client base. ',
        eventKey: "0"
    },
    {
        id : 2,
        question : 'Does my child get additional support?',
        answer: 'Yes! We give regular homework assignments to supplement class time to ensure that the child is motivated and engaged outside the classroom. There are tests, reviews and exercises to show mastery of the material. We do not assign grades but do recognize our student’s successes. We conduct live quizzes with other kids to make the course even more interesting! And full guidance is provided to the kid with the final capstone project.',
        eventKey: "2"
    },
    {
        id : 3,
        question : 'What technology do you need at home?',
        answer: 'You will need the following: 1) A computer with a keyboard, microphone, and speaker – almost all laptops bought within the last five years ago should have these; 2) A web browser installed, like Chrome (recommended) or Firefox or Safari; and 3) A reliable internet connection. Also, a headset is great so students can keep their hands free, but it isn’t required.',
        eventKey: 3
    },
    {
        id : 4,
        question : 'What is the time commitment?',
        answer: 'The duration of a course is usually around 15-20 sessions. It can go higher or lower depending on what pace the student learns the best. Classes are for 1-hour per session. Practice work is assigned after every class which will take approximately 1-hour to complete. Additional projects and activities will be available for students to explore based on their interests and time availability.',
        eventKey: 4
    },
    {
        id : 5,
        question : 'How frequent are the classes?',
        answer: 'These are live online classes. You can schedule these as per your convenience. One session is an hour long. Our recommendation is 3-4 classes per week. However, it is completely up to you on how you want to pace these and you may choose to have more or less number of classes per week.',
        eventKey: 5
    },
    {
        id : 6,
        question : 'What is the payment structure?',
        answer: 'The payment is done monthly. The payment will be calculated hourly, which allows the student to drop out anytime if he/she is not able to continue.',
        eventKey: 6
    }
]

export default faqs