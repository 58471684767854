import React from 'react'
import styles from './MissionSection.module.css'
import './MissionSection.css'
import {Container, Row, Col} from 'react-bootstrap'
import saly from '../../../../assets/images/Saly/Saly-mission.svg'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"

import SwiperCore, {Navigation} from 'swiper/core';
  
  SwiperCore.use([Navigation]);


const MissionSection = () => {
    return (
        <div className={styles.missionSection}>
            <Swiper navigation={true} className={styles.swiperContainer}>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Container>
                            <Row className={styles.slide1}>
                                <Col className={styles.slide11}>
                                    <h1 className={styles.slide11Heading}>Mission Statement🎯</h1>
                                    <p className={styles.slide11Desc}>Parents always want to provide only the best to their children which often leads them to turn a blind eye on obviously overpriced education. Our priorities lie with educating the child and not the monetary aspect that follows. We believe in being transparent about how your money is being utilized.</p>
                                </Col>
                                <Col className={styles.slide12}>
                                    <img src={saly} alt={Image} className={styles.slide11Img} />
                                </Col>
                            </Row>
                        </Container>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Container>
                            <div className={styles.slide2}>
                                <p className={styles.slide2Desc}>Observing the increasing capitalist mentality today especially in sectors where welfare must be the main priority, we find it appalling that the education of children is one such sector. We do not abet exploiting the parents' instincts to give their children quality education to line our own pockets.</p>
                            </div>
                        </Container>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                        <Container>
                            <div className={styles.slide2}>
                                <p className={styles.slide2Desc}>Our instructors are the pillars of our institution, therefore the majority of the revenue is being used to keep them motivated. 50% of the profit earned will be donated to educating the underprivileged children of India. We reach out to these ill-fated  children  ourselves and fund their school fees, books, and stationaries. The rest of the profit earned goes towards building this institution and upgrading our quality to make it the best experience for your child. </p>
                            </div>
                        </Container>
                    </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default MissionSection
