import React from 'react'
import {Container} from 'react-bootstrap'
import './AboutHero.css'

const AboutHero = () => {
    return (
        <div className="about-hero">
            <Container className="ah-wrapper">
                <h1>503</h1>
                <h2>The Page is under Construction</h2>
            </Container>
        </div>
    )
}

export default AboutHero
