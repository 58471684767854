import React from 'react'
import AboutHero from './sections/AboutSections/AboutHero/AboutHero'

const AboutScreen = () => {
    return (
        <div className="about-screen">
            <AboutHero />
        </div>
    )
}

export default AboutScreen
