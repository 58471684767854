import React from 'react'
import './FaqBody.css'
import faqs from '../../../../data/faq'
import {Accordion, Card, Container} from 'react-bootstrap'

const FaqBody = () => {

    return (
        <div className="faq-body">
            <Container>
            
                {
                    faqs.map(element => {
                        return(
                            <Accordion defaultActiveKey={element.eventKey}>
                            <Card className="faq-card">
                                <Card.Header className="faq-card-header">
                                    <Accordion.Toggle variant="link" eventKey={element.eventKey}>
                                        <button><i className="fas fa-plus"></i></button>
                                    </Accordion.Toggle>
                                    <h3>{element.question}</h3>
                                </Card.Header>
                                <Accordion.Collapse eventKey={element.eventKey}>
                                <Card.Body className="faq-card-body">
                                    <Container><p>{element.answer}</p></Container>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            </Accordion>  
                        )
                    })
                }          
            </Container>
        </div>
    )
}

export default FaqBody
