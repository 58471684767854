import newbieWeb from '../assets/images/courseimg/webdev.jpg'
import webAdv from '../assets/images/courseimg/webAdv.png'
import animScratch from '../assets/images/courseimg/scratch.jpg'
import scratchbeg from '../assets/images/courseimg/scratchbeg.png'
import python101 from '../assets/images/courseimg/pythoncoding.png'
import pyhtonpro from '../assets/images/courseimg/pythonpro.png'
import cAdv from '../assets/images/courseimg/cAdv.png'
import cbeg from '../assets/images/courseimg/cbeg.png'
import cpp from '../assets/images/courseimg/c++.jpg'
import cppAdv from '../assets/images/courseimg/cppAdv.png'
import javaAdv from '../assets/images/courseimg/javaAdv.png'
import java from '../assets/images/courseimg/java.jpg'
import jsMaster from '../assets/images/courseimg/jsMaster.png'


const courses = [
    {
        id: '1',
        title: "Newbie@web",
        category: 'webdev',
        img: newbieWeb,
        descS: 'Kids are taught the building blocks of web development',
        descF: ' by learning how to design simple web pages, web profile cards, create animations, gradients, effects and so much more. The curriculum is formulated to be fun and interactive and suited perfectly for kids.',
        difficulty: 'Beginner',
        color: {
            hex: 'rgb(253, 223, 223)',
            name: 'lg-pink'
        },
        price: '12',
        curriculum : [
            {
                id: '1',
                title: 'Introduction to Web Development and HTML',
                desc : 'Web development and its structure, The First Web page, Installation of text editor'
            },
            {
                id: '2',
                title: 'Skeleton of a Web Page',
                desc : 'Basic document structure of HTML, Anatomy of elements of HTML, Headings, Paragraphs, Learn to style in HTML'
            },
            {
                id: '3',
                title: 'Formatting and text',
                desc : 'Style attributes, Text Formatting'
            },
            {
                id: '4',
                title: 'Div, span and Lists',
                desc : 'Div, Span, Ordered Lists, Unordered Lists'
            },
            {
                id: '5',
                title: 'Tables',
                desc : 'Simple Tables Styling a table Nested tables'
            },
            {
                id : '6',
                title: 'Forms Basics',
                desc: 'Form tags, Input Types - I'
            },
            {
                id: '7',
                title: 'Forms Advanced',
                desc : 'Input Types - II, Form Validations'
            },
            {
                id: '8',
                title: 'introduction to CSS',
                desc : 'Syntax Types of adding CSS Colours - types First Stylesheet'
            },
            {
                id: '9',
                title: 'BBB - Background, border, box model',
                desc : 'Background colours Background ImagesBorders, Box model'
            },
            {
                id: '10',
                title: 'CSS Selectors',
                desc : 'Types of selectors Examples'
            },
            {
                id: '11',
                title: 'Building an Image Gallery',
                desc : 'Image Gallery with HTML and CSS'
            },
            {
                id: '12',
                title: 'Fonts and Icons',
                desc : 'Google Fonts, Font styling, Adding icons, Text effects'
            },
            {
                id: '13',
                title: 'Building a Blog',
                desc : 'Blog site'
            },
            {
                id: '14',
                title: 'Basics of JS, Syntax, Variables, Age Calculator'
            }
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Learn basic web design"
            },
            {
                id: 2,
                text: "Build responsive sites"
            },
            {
                id: 3,
                text: "Build your own portfolio website"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },    
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 9,
                text: "Supportive Online Student Community"
            },    
            {
                id: 10,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 11,
                text: "Start from Scratch"
            },
            {
                id: 12,
                text: "Quiz Mania with the peers"
            },
            {
                id: 13,
                text: "Tantalizing tasks and puzzles"
            },    
            {
                id: 14,
                text: "Astute Assessments"
            },
            {
                id: 15,
                text: "Summarized pre-written materials"
            },
            {
                id: 16,
                text: "Final Capstone Project"
            }
        ],
        pre: 'No previous knowledge of coding required'
    },
    {
        id: '2',
        title: 'Mastery@web',
        category: 'webdev',
        img: webAdv,
        descS: 'Scale up your knowledge of HTML and CSS',
        descF : ' and show the world your ingenious creativity with our advanced web development course. This module is devised to build on your existing knowledge of the subject and enhance it to a new level of excellence.',
        difficulty: 'Advanced',
        color: {
            hex: 'rgb(252, 247, 222)',
            name: 'lg-yellow'
        },
        price: '14',
        curriculum : [
            {
                id: '1',
                title: 'Learn to inspect!',
                desc : 'Inspect using the chrome dev tools, CSS'
            },
            {
                id: '2',
                title: 'Make your site responsive!',
                desc : 'Bootstrap - Breakpoints and containers'
            },
            {
                id: '3',
                title: 'Bootstraps Components',
                desc : 'Buttons, Badgers, Alerts and more!'
            },
            {
                id: '4',
                title: 'Manoeuvre the grids!',
                desc : 'Bootstrap grids and its play on the responsiveness'
            },
            {
                id: '5',
                title: 'Navigate your navbars:)',
                desc : 'Bootstrap Navbars and icons'
            },
            {
                id: '6',
                title: 'Other stuffs in the Bootstrap Bag',
                desc : 'Bootstrap - Card, Forms, 	Carousel, Modal, Paginations, Spinners'
            },
            {
                id: '7',
                title: 'A website project with Bootstrap',
                desc : 'Build a project with the help of Bootstrap from our list of projects or make your own!'
            },
            {
                id: '8',
                title: 'Introduction to Jquery and the why of it.',
                desc : 'What is Jquery? Why do we need it?'
            },
            {
                id: '9',
                title: 'Building blocks of Jquery',
                desc : 'Jquery syntax, Selectors and its types'
            },
            {
                id: '10',
                title: 'Jquery events',
                desc : 'Basic DOM event methods'
            },
            {
                id: '11',
                title: 'Bring it on:)',
                desc : 'Jquery effects - Hide, show, fade, slide and animate'
            },
            {
                id: 12,
                title: 'A deep dive again!',
                desc: 'More effects - stop(), callback and chaining'
            },
            {
                id: 13,
                title: 'Project II',
                desc: 'Build a project with everything that was covered from our list of projects or make your own!'
            },
            {
                id: 14,
                title: 'Intro to GitHub',
                desc: 'Learn the basics of Github'
            },
            {
                id: 15,
                title: 'The grand Finale - Deploy your project for FREE',
                desc: 'A step by step process of deploying your portfolio website!'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Build responsive sites"
            },
            {
                id: 2,
                text: "Learn advanced web design"
            },
            {
                id: 3,
                text: "Host your website!"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes "
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },    
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 16,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 17,
                text: "Astute Assessments"
            },
            {
                id: 18,
                text: "Summarized pre-written materials"
            },    
            {
                id: 19,
                text: "Final Capstone Project "
            },    
        ],
        pre: "To have completed the course- Newbie@web and Master JS Or Have basic knowledge of the programming language",
    },
    {
        id:'3',
        title: 'Python 101',
        category: 'python',
        img: python101,
        descS: 'This course equips kids with the ABCs of Python and',
        descF: ' familiarises them with the fundamentals. Python is a powerful general-purpose programming language that is being applied in various fields. This is an immensely useful and easy to learn course for beginners.',
        difficulty: 'Beginner',
        color: {
            hex: 'rgb(222, 253, 224)',
            name: 'lg-green'
        },
        price: '12',
        curriculum : [
            {
                id: '1',
                title: 'What is Python!?',
                desc : 'Introduction - Python Getting familiar with the platform. Basic Syntax.print() function'
            },
            {
                id: '2',
                title: 'The ABC of python',
                desc : 'Python Variables Data Types.Comments.'
            },
            {
                id: '3',
                title: 'Operators and Expressions',
                desc : 'Arithmetic operators, Assignment operators ,Logical operators, Comparison operators, Expressions'
            },
            {
                id: '4',
                title: 'A deep dive into numbers',
                desc : 'Introduction to data types Integer, Float, type() function'
            },
            {
                id: '5',
                title: '“Hello, Strings!"',
                desc : 'Creating a string String Indexing and slicing String properties String methods'
            },
            {
                id: '6',
                title: 'Playing around with Lists and Tuples',
                desc : 'Creating a list Indexing and slicing on a list List methods Tuples Tuple methods'
            },
            {
                id: '7',
                title: 'Let’s get over them! - Sets, Booleans and Dictionaries',
                desc : 'Sets and methods  Booleans and boolean operations Creating Dictionaries Accessing objects Dictionary methods'
            },
            {
                id: '8',
                title: 'THE BIG TEST',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '9',
                title: 'Control Flows: if-else',
                desc : 'If ,If-else, If-elif-else, Pass statement.'
            },
            {
                id: '10',
                title: 'Let’s go on a loop!',
                desc : 'For loop, range() function, Nesting if statements in the loop'
            },
            {
                id: '11',
                title: 'Conditional loops - Part 2',
                desc : 'While loop, Logical programs, Nested Loops'
            },
            {
                id: '12',
                title: 'Take a break, continue and pass:)',
                desc : 'Break, Continue, Pass, More functions - zip, enumerate, randint, shuffle'
            },
            {
                id: '13',
                title: 'Comprehend the list comprehension and break a leg',
                desc : 'List Comprehension'
            },
            {
                id: '14',
                title: 'THE BIG TEST -2',
                desc : 'Test on the concepts learnt so far.'
            },
            {
                id: '15',
                title: 'The Grand Finale',
                desc : 'The final Project'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Build python games"
            },
            {
                id: 2,
                text: "Build a project from our list of starter projects"
            },
            {
                id: 3,
                text: "Solve computation problems"
            },
            {
                id: 4,
                text: "Learn debugging and more"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },
            {
                id: 13,
                text: "Tantalizing tasks and puzzles"
            },    
            {
                id: 14,
                text: "Summarized pre-written materials"
            },
            {
                id: 15,
                text: "Final Capstone Project"
            },
                
        ],
        pre: 'No previous knowledge of coding required'
        
    },
    {
        id: '4',
        title: 'Pro.py Advanced',
        category: 'python',
        img: pyhtonpro,
        descS: 'This module is for kids who have taken the Python Basics',
        descF: ' course or for those who are confident with their hold on the programming language. Our module is devised to master python and its applications to endow kids with unlimited capabilities.',
        difficulty: 'Advanced',
        color: {
            hex: 'rgb(222, 243, 253)',
            name: 'lg-blue'
        },
        price: '14',
        curriculum : [
            {
                id: '1',
                title: 'Make your Functions!',
                desc : 'Creating a function Arguments Returning a value'
            },
            {
                id: '2',
                title: 'Recursion Scope of variables',
                desc : 'Converting loops to recursive functions.  Global, local and non-local variables'
            },
            {
                id: '3',
                title: 'Lambda expressions, Maps and Filter',
                desc : 'Lambda expressions, Maps, Filters'
            },
            {
                id: '4',
                title: 'Python packages and modules',
                desc : 'Built-in Modules, User-defined modules, Packages'
            },
            {
                id: '5',
                title: 'BIG TEST - 1',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '6',
                title: 'Object Oriented Programming - Classes and objects',
                desc : 'Basic OOPs concepts, Creating classes and objects, Constructors'
            },
            {
                id: '7',
                title: 'Inheritance',
                desc : 'Parent class and child class, Types of inheritance, Multiple Inheritance, super() function'
            },
            {
                id: '8',
                title: 'Polymorphism and special methods',
                desc : 'Operator overloading Init, str, len, del'
            },
            {
                id: '9',
                title: 'Python Files',
                desc : 'File operations - open, close, read and write File methods'
            },
            {
                id: '10',
                title: 'Errors and exception handling',
                desc : 'Basic error and its types , Catching exceptions, User-defined exceptions'
            },
            {
                id: '11',
                title: 'BIG TEST - 2',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '12',
                title: 'The final episode',
                desc : 'Build a capstone project'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Build python games"
            },
            {
                id: 2,
                text: "Build a project from our list of starter projects"
            },
            {
                id: 3,
                text: "Solve computation problems"
            },
            {
                id: 4,
                text: "Learn debugging and more"
            },
            {
                id: 5,
                text: "Learn advanced concepts like Recursion, OOPS, files and exception handling "
            },
            {
                id: 6,
                text: "Interactive Live Classes"
            },    
            {
                id: 7,
                text: "Structured and Comprehensive Curriculum"
            },
            {
                id: 8,
                text: "Weekly Progress Report & Alerts"
            },    
            {
                id: 9,
                text: "Homework Help"
            },
            {
                id: 10,
                text: "Individual Course Certification"
            },
            {
                id: 11,
                text: "Highly Qualified Instructors"
            },
            {
                id: 12,
                text: "Supportive Online Student Community"
            },
            {
                id: 13,
                text: "Hands-on, Project Based Learning"
            },
            {
                id: 14,
                text: "Tantalizing tasks and puzzles"
            },    
            {
                id: 15,
                text: "Summarized pre-written materials"
            },
            {
                id: 16,
                text: "Final Capstone Project"
            },    
        ],
        pre: 'To have completed the course- Python 101  Or Have basic knowledge of the programming language'
    },
    {
        id:'5',
        title: 'Logic Programming with Scratch',
        category: 'Scratch',
        img: scratchbeg,
        descS: 'Our module for  Logic',
        descF: ' Programming with Scratch covers a variety of topics using interesting projects to enable the kids to have fun with it and learn in the most optimal way possible. By the end of the course, the child will be able to incorporate all their lessons to create their own blocks for their final project.',
        difficulty: 'Beginner',
        color: {
            hex: 'rgb(240, 222, 253)',
            name: 'lg-violet'
        },
        price: '12',
        curriculum : [
            {
                id: '1',
                title: 'The Scratch Editor',
                desc : 'Basic explanation and understanding of the Console ∙ Blocks ∙ Sequence Construct ∙ Basic Project'
            },
            {
                id: '2',
                title: 'Motion and Sound',
                desc : 'Running code by clicking on Sprite ∙ Moving the sprite (Mouse-Pointer Sliding) ∙ Playing Sounds ∙ Changing Costumes ∙ Changing Backdrop ∙ Music Band Project ∙ Happy Birthday Song'
            },
            {
                id: '3',
                title: 'Aim-possible',
                desc : 'Coordinates ∙ Grids ∙ Directions and Angles ∙ Archery Project'
            },
            {
                id: '4',
                title: 'Looping Constructs',
                desc : 'Reduction of Code ∙ Moving in a Square ∙ Zig-zag motion '
            },
            {
                id: '5',
                title: 'Catch me if you can',
                desc : 'Variables as scores ∙ Basic Mathematical Operations- Addition, Subtraction, Multiplication ∙ Counting Apples Project ∙ Voting Project'
            },
            {
                id: '6',
                title: 'More on Math',
                desc : 'Boolean logic (and, or, not) ∙ Comparison operators (<,>,=) ∙ Mathematical operations (mod, abs, round) ∙ Who won the Voting Poll? Project '
            },
            {
                id: '7',
                title: 'At the Same Time',
                desc : 'Coordination and synchronisation (broadcasting messages) ∙ Control Flow ∙ Basic Idea of functions'
            },
            {
                id: '8',
                title: 'A-Maze-ing',
                desc : 'If-Else Construct ∙ Maze Project (Without time constraint)'
            },
            {
                id: '9',
                title: 'Time Waits for None',
                desc : 'Waiting ∙ Adding Time Constraints ∙ Maze Project'
            },
            {
                id: '10',
                title: 'Chatbot',
                desc : 'Strings ∙ Chatbot Project ∙ Quiz Project'
            },
            {
                id: '11',
                title: 'A Short Story',
                desc : 'Narrating a Story Project'
            },
            {
                id: '12',
                title: 'The Checklist',
                desc : 'Lists ∙ Memory Project ∙ A Conversation Project (implementing strings and lists) '
            },
            {
                id: '13',
                title: 'Accessing Items in a List',
                desc : 'Selecting Random items ∙ Catch The Dots Project ∙ What’s in the Basket? Project'
            },
            {
                id: '14',
                title: 'Debugging',
                desc : 'Abstract Idea of Debugging ∙ Find the errors'
            },
            {
                id: '15',
                title: 'End is the Beginning ',
                desc : 'Create your Own Blocks ∙ Summary ∙ Final Project'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Create interactive animations using conditionals with motions"
            },
            {
                id: 2,
                text: " Learn debugging and game design"
            },
            {
                id: 3,
                text: "Logical challenges to introduce real world programming "
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Start from Scratch"
            },
            {
                id: 10,
                text: "Quiz Mania with the peers"
            },
            {
                id: 11,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 12,
                text: "Astute Assessments"
            },    
            {
                id: 11,
                text: "Summarized pre-written materials"
            },
            {
                id: 12,
                text: "Final Capstone Project"
            },    
        ],
        pre: 'No previous knowledge of coding required'
    },
    {
        id:'6',
        title: 'Basics.java',
        category: 'Java',
        img: java,
        descS: 'Kids learn the fundamentals of the most popular',
        descF: ' programming language used to create Web applications and platforms. JAVA is a flexible and easy to learn coding language and our curriculum teaches kids to be an architect and showcase their imagination. ',
        difficulty: 'Beginner',
        color: {
            hex: 'rgb(253, 223, 223)',
            name: 'lg-pink'
        },
        price: '12',
        curriculum : [
            {
                id: '1',
                title: 'JAVA',
                desc : 'JAVA Introduction, Installation, JDK, JRE, JVM'
            },
            {
                id: '2',
                title: 'Java Syntax',
                desc : 'Class and Objects, Output function ,Access modifiers, Main class declaration, Comments'
            },
            {
                id: '3',
                title: 'Java Basic code',
                desc : 'Method declaration, Data types, Return function, Variables, Sample program'
            },
            {
                id: '4',
                title: 'Java Operators',
                desc : 'All operators in JAVA, Input function – Scanner class, Keywords, Programs related to operators'
            },
            {
                id: '5',
                title: 'Java Loops',
                desc : 'For Loop, While Loop, Do While Loop, Break, continue, If statements, Nested If else statements'
            },
            {
                id: '6',
                title: 'Java constructors',
                desc : 'Rules, Declaration, Advantages, Types of constructors'
            },
            {
                id: '7',
                title: 'TEST 1',
                desc : ''
            },
            {
                id: '8',
                title: 'Static and This keyword',
                desc : 'Static keyword, This keyword, Uses, Rules'
            },
            {
                id: '9',
                title: 'Java Methods',
                desc : 'Method overloading, Method overriding, D/B Overloading overriding'
            },
            {
                id: '10',
                title: 'Java Array',
                desc : 'Array declaration, Array initialization'
            },
            {
                id: '11',
                title: 'Java Array applications',
                desc : 'Array implementation, Array Programs'
            },
            {
                id: '12',
                title: 'Java String',
                desc : 'String literals, String comparison, String concatenation, Substring'
            },
            {
                id: '13',
                title: 'Java String',
                desc : 'String methods. toString method'
            },
            {
                id: '14',
                title: 'TEST 2',
                desc : ''
            },
            {
                id: '15',
                title: 'Case study(Project)',
                desc : ''
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Data Types"
            },
            {
                id: 2,
                text: "Operators,operands and control statements"
            },
            {
                id: 3,
                text: "Loops and array"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 16,
                text: "Astute Assessments "
            },
            {
                id: 17,
                text: "Summarized pre-written materials"
            },
            {
                id: 18,
                text: "Final Capstone Project"
            },    
        ],
        pre: 'No previous knowledge of coding required'
    },
    {
        id:'7',
        title: 'Expert.java',
        category: 'Java',
        img: javaAdv,
        descS: 'Enable your kid to broaden their knowledge by learning',
        descF: ' more of what JAVA has to offer. Each new version of Java comes with several new features and enhanced performance. Our updated module is tailored to deliver the latest and most beneficial concepts to kids who will start thinking like a programmer. ',
        difficulty: 'Advanced',
        color: {
            hex: 'rgb(252, 247, 222)',
            name: 'lg-yellow'
        },
        price: '14',
        curriculum : [
            {
                id: '1',
                title: 'String classes',
                desc : 'String Buffer class, String Builder class, String tokenizer class, String vs StringBuffer, StringBuffer vs StringBuilder'
            },
            {
                id: '2',
                title: 'Java Inheritance',
                desc : 'IS-A relationship, Has-A relationship, Super keyword'
            },
            {
                id: '3',
                title: 'Polymorphism',
                desc : 'Runtime polymorphism, Final keyword'
            },
            {
                id: '4',
                title: 'Java Bindings',
                desc : 'Static binding, Dynamic binding'
            },
            {
                id: '5',
                title: 'Java Abstraction',
                desc : 'Abstract class, Interface, Abstract class vs Interface'
            },
            {
                id: '6',
                title: 'Encapsulation',
                desc : 'Package ,Java Encapsulation'
            },
            {
                id: '7',
                title: 'TEST 1',
                desc : ''
            },
            {
                id: '8',
                title: 'Wrapper class',
                desc : 'Primitive datatypes, Wrapper class, Autoboxing, Unboxing'
            },
            {
                id: '9',
                title: 'Java Recursion',
                desc : 'Call by values, Call by reference, Command line Arg'
            },
            {
                id: '10',
                title: 'Java Exception Handling',
                desc : 'Checked Exception, Unchecked Exception'
            },
            {
                id: '11',
                title: 'Throwable exception',
                desc : 'Throws, Throw, Throwable, Final Vs Finally Vs Finalize'
            },
            {
                id: '12',
                title: 'Java Collections',
                desc : 'Collections import, ArrayList, LinkedList, Collection iteration'
            },
            {
                id: '13',
                title: 'Collection Sets',
                desc : 'Hashset, LinkedHashSet, TreeSet'
            },
            {
                id: '14',
                title: 'TEST 2',
                desc : ''
            },
            {
                id: '15',
                title: 'Case study(Project)',
                desc : ''
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Functions and recursions"
            },
            {
                id: 2,
                text: "Structure and file handling"
            },
            {
                id: 3,
                text: "OOPS"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 16,
                text: "Astute Assessments"
            },
            {
                id: 17,
                text: "Summarized pre-written materials"
            },
            {
                id: 18,
                text: "Final Capstone Project"
            },    
        ],
        pre: 'To have completed the course- Basics.java Or Have basic knowledge of the programming language'
    },
    {
        id:'8',
        title: 'Animations and Games',
        category: 'Scratch',
        img: animScratch,
        descS: 'Kids will get an outlet for their creative instincts',
        descF: ' with what they learn in this course. The module is loaded with fun and inventive lessons and projects allowing kids to expand their boundaries and come up with great ideas!',
        difficulty: 'Beginner',
        color: {
            hex: 'rgb(222, 253, 224)',
            name: 'lg-green'
        },
        price: '12',
        curriculum : [
            {
                id: '1',
                title: 'Move the Sprite',
                desc : 'The Scratch Editor, Blocks, Moving the sprite – Square, Triangle, Circle'
            },
            {
                id: '2',
                title: 'Motion and Sound',
                desc : 'Playing Sounds,Changing Size, Costumes, Backdrop ,Dancing Crab Project, Hide and Show characters'
            },
            {
                id: '3',
                title: 'Greeting Card',
                desc : 'Happy Birthday Song '
            },
            {
                id: '4',
                title: 'Paint with scratch',
                desc : 'pen up and pen down'
            },
            {
                id: '5',
                title: 'Animate names ',
                desc : 'Basic animations'
            },
            {
                id: '6',
                title: 'Design your Sprite',
                desc : 'Creative space to bolster the imaginations and create their own characters'
            },
            {
                id: '7',
                title: 'Race Car Game',
                desc : 'A game to test the logical thinking'
            },
            {
                id: '8',
                title: 'Quiz',
                desc : 'Asking and Waiting - Time techniques'
            },
            {
                id: '9',
                title: 'Chatbot - 1',
                desc : 'Conversation, Broadcast messages'
            },
            {
                id: '10',
                title: 'Chatbot - 2',
                desc : 'Story narration'
            },
            {
                id: '11',
                title: 'Virtual tour',
                desc : 'Click on sprites and display messages'
            },
            {
                id: '12',
                title: 'Catch me if you can - 1',
                desc : 'Add points when a flying bubble is clicked'
            },
            {
                id: '13',
                title: 'Catch me if you can - 2',
                desc : 'Add points when you catch an apple in the basket'
            },
            {
                id: '14',
                title: 'Pong game',
                desc : 'Hit the bouncing ball and try to keep it in the air'
            },
            {
                id: '15',
                title: 'Simple Calculator ',
                desc : 'one-digit addition and subtraction'
            },
            {
                id: '16',
                title: 'The Grand Finale',
                desc : 'The final capstone project implementing the knowledge gained from this course'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "15 Gaming projects"
            },
            {
                id: 2,
                text: "Create interactive animations using conditionals with motions"
            },
            {
                id: 3,
                text: " Learn debugging and game design "
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 16,
                text: "Astute Assessments"
            },
            {
                id: 17,
                text: "Summarized pre-written materials"
            },
            {
                id: 18,
                text: "Final Capstone Project"
            },    
        ],
        pre: 'To have completed the course- Logic Programming with scratch Or Have basic knowledge of the programming language'
    },
    {
        id:'9',
        title: 'ABC of C++',
        category: 'C++',
        emoji: "far fa-smile-beam",
        img: cpp,
        descS: 'Kids will get an outlet for their creative instincts',
        descF: ' with what they learn in this course. The module is loaded with fun and inventive lessons and projects allowing kids to expand their boundaries and come up with great ideas!',
        difficulty: 'Beginner',
        color: {
            hex: 'rgb(222, 243, 253)',
            name: 'lg-blue'
        },
        price: '12',
        curriculum : [
            {
                id: '1',
                title: 'The ABC of C++',
                desc : 'Introduction - C++, Getting familiar with the platform., Basic Syntax.'
            },
            {
                id: '2',
                title: 'The Backbone of coding with C++',
                desc : 'C++ I/O, Data types, Variables, Comments'
            },
            {
                id: '3',
                title: 'Operators and Expressions',
                desc : 'Arithmetic operators, Assignment operators ,Logical operators, Comparison operators, Expressions'
            },
            {
                id: '4',
                title: 'A deep dive into numbers',
                desc : 'More programs on Int, Float, Double'
            },
            {
                id: '5',
                title: 'Second dive into other data types',
                desc : 'More programs on char, bool'
            },
            {
                id: '6',
                title: 'Let’s get over them!',
                desc : 'Escape sequence, const, literals, Type conversions'
            },
            {
                id: '7',
                title: 'THE BIG TEST',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '8',
                title: 'Flow Control: if-else ',
                desc : 'If .If-else, If-else if-else.'
            },
            {
                id: '9',
                title: 'Let’s go on a loop!',
                desc : 'For loop , Nesting if statements in the loop'
            },
            {
                id: '10',
                title: 'Conditional loops - Part 2',
                desc : 'While loop, Logical programs, Nested Loops'
            },
            {
                id: '11',
                title: 'Conditional loops - Part 3',
                desc : 'Do...While loop, Logical programs, Nested Loops'
            },
            {
                id: '12',
                title: 'Take a break, continue and switch:)',
                desc : 'Break, Continue, Switch Statements'
            },
            {
                id: '13',
                title: 'Playing around with loops',
                desc : 'Goto statements, More practice on flow control and loops'
            },
            {
                id: '14',
                title: 'THE BIG TEST -2',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '15',
                title: 'Let’s group them up! - Arrays',
                desc : 'Single dimensional arrays - Initialization and Printing'
            },
            {
                id: '16',
                title: 'More to group!',
                desc : 'Single dimensional arrays - Storage and computations, handling different data types'
            },
            {
                id: '17',
                title: 'Multidimensional View',
                desc : 'Multidimensional arrays - Manipulation of  2D and 3D arrays'
            },
            {
                id: '18',
                title: 'Playing around with arrays',
                desc : 'More practice on arrays'
            },
            {
                id: '19',
                title: 'BIG TEST 3',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '20',
                title: 'The Grand Finale',
                desc : 'The final Project'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Data types"
            },
            {
                id: 2,
                text: "Operators,operands and control statements"
            },
            {
                id: 3,
                text: "Loops and array"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 16,
                text: "Astute Assessments"
            },
            {
                id: 17,
                text: "Summarized pre-written materials"
            },
            {
                id: 18,
                text: "Final Capstone Project"
            },   
        ],
        pre: 'No previous knowledge of coding required'
    },
    {
        id:'10',
        title: 'XYZ of C++',
        category: 'C++',
        img: cppAdv,
        descS: 'C++ is a highly portable and in-demand programming',
        descF: ' language making it an indispensable language to know. This module is a deep dive into the intricacies of C++ made simple and coherent for kids who are familiar with the fundamentals.',
        difficulty: 'Advanced',
        color: {
            hex: 'rgb(240, 222, 253)',
            name: 'lg-violet'
        },
        price: '14',
        curriculum : [
            {
                id: '1',
                title: 'Make your Functions!',
                desc : 'Declaration, Function call, Function parameters, Definition of a function'
            },
            {
                id: '2',
                title: 'Types of Functions',
                desc : '4 Types of defining a function'
            },
            {
                id: '3',
                title: 'Recursion Scope of variables',
                desc : 'Converting loops to recursive functions, Global, local and non-local variables'
            },
            {
                id: '4',
                title: 'More functions',
                desc : 'Function overloading, Arrays and functions'
            },
            {
                id: '5',
                title: 'Structures in C++',
                desc : 'Structures, Structures and Functions,Enumerators'
            },
            {
                id: '6',
                title: 'BIG TEST - 1',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '7',
                title: 'Object Oriented Programming - Classes and objects',
                desc : 'Basic OOPs concepts Creating classes and objects Constructors'
            },
            {
                id: '8',
                title: 'Overloading with OOPS',
                desc : 'Objects and functions, Operator Overloading'
            },
            {
                id: '9',
                title: 'Inheritance',
                desc : 'Parent class and child class, Types of inheritance, Multiple Inheritance'
            },
            {
                id: '10',
                title: 'BIG TEST 2',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '11',
                title: 'Pointers',
                desc : 'Introduction to pointers, Pointers and Arrays'
            },
            {
                id: '12',
                title: 'Playing around with Pointers',
                desc : 'Pointers and functions More practice'
            },
            {
                id: '13',
                title: 'File Handling',
                desc : 'File operations - open, close, read and write , File methods'
            },
            {
                id: '14',
                title: 'BIG TEST - 3',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '15',
                title: 'The final episode',
                desc : 'Build a capstone project'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Functions and recursions"
            },
            {
                id: 2,
                text: "Structure and file handling"
            },
            {
                id: 3,
                text: "OOPS"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 16,
                text: "Astute Assessments"
            },
            {
                id: 17,
                text: "Summarized pre-written materials"
            },
            {
                id: 18,
                text: "Final Capstone Project"
            },    
        ],
        pre: 'To have completed the course- ABC of C++ Or Have basic knowledge of the programming language '
    },
    {
        id:'11',
        title: 'Rookie.c',
        category: 'C',
        img: cbeg,
        descS: 'C language is the perfect starter course for brand',
        descF: ' new coders. This course highlights the building blocks of programming and aids the onset of the kid’s interest in coding. We build intrigue and impart our passion for the subject to your child along the course of the edifying and interactive sessions. ',
        difficulty: 'Beginner',
        color: {
            hex: 'rgb(253, 223, 223)',
            name: 'lg-pink'
        },
        price: '12',
        curriculum : [
            {
                id: '1',
                title: 'The ABC of C',
                desc : 'Introduction - C, Getting familiar with the platform.Basic Syntax'
            },
            {
                id: '2',
                title: 'The Backbone of coding with C',
                desc : 'C I/O, Keywords and IdentifiersData types, Variables, Comment'
            },
            {
                id: '3',
                title: 'Operators and Expressions',
                desc : 'Arithmetic operators, Assignment operators, Logical operators ,Comparison operators ,Expressions'
            },
            {
                id: '4',
                title: 'A deep dive into numbers',
                desc : 'More programs on Int, Float, Double and more'
            },
            {
                id: '5',
                title: 'Second dive into other data types',
                desc : 'Programs on other data types'
            },
            {
                id: '6',
                title: 'Let’s get over them!',
                desc : 'Escape sequence, const, literals, Type conversions'
            },
            {
                id: '7',
                title: 'THE BIG TEST',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '8',
                title: 'Flow Control: if-else',
                desc : 'If .If-else. If-else if-else'
            },
            {
                id: '9',
                title: 'Let’s go on a loop!',
                desc : 'For loop, Nesting if statements in the loop'
            },
            {
                id: '10',
                title: 'Conditional loops - Part 2',
                desc : 'While loop, Logical programs, Nested Loops'
            },
            {
                id: '11',
                title: 'Conditional loops - Part 3',
                desc : 'Do...While loop, Logical programs, Nested Loops'
            },
            {
                id: '12',
                title: 'Take a break, continue and switch:)',
                desc : 'Break, Continue, Switch Statements'
            },
            {
                id: '13',
                title: 'Playing around with loops',
                desc : 'Goto statements, More practice on flow control and loops'
            },
            {
                id: '14',
                title: 'THE BIG TEST -2',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '15',
                title: 'Let’s group them up! - Arrays',
                desc : 'Single dimensional arrays - Initialization and Printing'
            },
            {
                id: '16',
                title: 'More to group!',
                desc : 'Single dimensional arrays - Storage and computations, handling different data types'
            },
            {
                id: '17',
                title: 'Multidimensional View',
                desc : 'Multidimensional arrays - Manipulation of  2D and 3D array'
            },
            {
                id: '18',
                title: 'Playing around with arrays',
                desc : 'More practice on arrays'
            },
            {
                id: '19',
                title: 'BIG TEST 3',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '20',
                title: 'The Grand Finale',
                desc : 'The final Project'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Data types"
            },
            {
                id: 2,
                text: "Operators,operands and control statements"
            },
            {
                id: 3,
                text: "Loops and array"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 16,
                text: "Astute Assessments"
            },
            {
                id: 17,
                text: "Summarized pre-written materials"
            },
            {
                id: 18,
                text: "Final Capstone Project"
            },
        ],
        pre: "No previous knowledge of coding required"
    },
    {
        id:'12',
        title: 'Grandmaster.c',
        category: 'C',
        img: cAdv,
        descS: 'C is used in all the operating systems in your computer',
        descF: " and your cellphone and even in programming robots. This advanced course is to surpass and refine the kid's C knowledge and guide them on how to apply their skills.",
        difficulty: 'Advanced',
        color: {
            hex: 'rgb(252, 247, 222)',
            name: 'lg-yellow'
        },
        price: '14',
        curriculum : [
            {
                id: '1',
                title: 'Make your Functions!',
                desc : 'Declaration, Function call, Function parameters, Definition of a function'
            },
            {
                id: '2',
                title: 'Types of Functions',
                desc : '4 Types of defining a function'
            },
            {
                id: '3',
                title: 'Recursion Scope of variables',
                desc : 'Converting loops to recursive functions, Global, local and non-local variables'
            },
            {
                id: '4',
                title: 'More functions',
                desc : 'Function overloading, Arrays and functions'
            },
            {
                id: '5',
                title: 'Structures in C++',
                desc : 'Structures, Structures and Functions',
            },
            {
                id: '6',
                title: 'Playing around with Structures',
                desc : 'Enumerators, Union, More programs on Structures'
            },
            {
                id: '7',
                title: 'Strings in C',
                desc : 'Strings, Functions and strings'
            },
            {
                id: '8',
                title: 'BIG TEST - 1',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '9',
                title: 'Pointers',
                desc : 'Introduction to pointers ,Pointers and Arrays'
            },
            {
                id: '10',
                title: 'Playing around with Pointers',
                desc : 'Pointers and functions , More practice'
            },
            {
                id: '11',
                title: 'File Handling',
                desc : 'File operations - open, close, read and write, File methods'
            },
            {
                id: '12',
                title: 'BIG TEST - 2',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '13',
                title: 'The final episode',
                desc : 'Build a capstone project'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Functions and recursions"
            },
            {
                id: 2,
                text: "Structure and file handling"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 16,
                text: "Astute Assessments"
            },
            {
                id: 17,
                text: "Summarized pre-written materials"
            },
            {
                id: 18,
                text: "Final Capstone Project"
            },    
        ],
        pre: "To have completed the course- Rookie.c Or Have basic knowledge of the programming language "
    },
    {
        id:'13',
        title: 'Master JS',
        category: 'webdev',
        img: jsMaster,
        descS: 'This course focuses on breaking down the more complex',
        descF: " more complex aspects of JavaScript and explaining it in a simple and informative manner. This course is designed to comprehend and apply the language to its fullest extent and make your child’s abilities limitless.",
        difficulty: 'Intermediate',
        color: {
            hex: 'rgb(222, 253, 224)',
            name: 'lg-green'
        },
        price: '12',
        curriculum : [
            {
                id: '1',
                title: 'More Basics of JS',
                desc : 'JS Data Types, Operators, comments'
            },
            {
                id: '2',
                title: 'Operators and Flow Control',
                desc : 'Comparison and logical operators, If else statements'
            },
            {
                id: '3',
                title: 'Control Statements',
                desc : 'For loop, while loop'
            },
            {
                id: '4',
                title: 'Playing around with control statements',
                desc : 'Switch, Break and continue'
            },
            {
                id: '5',
                title: 'Javascript Functions',
                desc : 'Functions, Scope and Recursion',
            },
            {
                id: '6',
                title: 'Objects and Methods',
                desc : 'JS objects, method and prototype'
            },
            {
                id: '7',
                title: 'Big test 1',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '8',
                title: 'Events',
                desc : 'onChange, onClick, onLoad'
            },
            {
                id: '9',
                title: 'Strings and numbers',
                desc : 'Strings, String Methods, Handling Numbers'
            },
            {
                id: '10',
                title: 'Js Arrays',
                desc : 'Array Methods, Array Sort, Array Iteration'
            },
            {
                id: '11',
                title: 'JS Dates',
                desc : 'Formats, Get and Set methods'
            },
            {
                id: '12',
                title: 'JS Math',
                desc : 'Mathematics Methods'
            },
            {
                id: '13',
                title: 'JS Error Handling',
                desc : 'Try Catch Finally Throw'
            },
            {
                id: '14',
                title: 'JSON for Data storage',
                desc : 'javascript objects'
            },
            {
                id: '15',
                title: 'Big Test 2',
                desc : 'Test on the concepts learnt so far'
            },
            {
                id: '16',
                title: 'Grand Finale',
                desc : 'The final Project'
            },
        ],
        projectOverview: [
            {
                id:1,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:2,
                img: 'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=752&q=80'
            },
            {
                id:3,
                img: 'https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
            {
                id:4,
                img: 'https://images.unsplash.com/photo-1454923634634-bd1614719a7b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
            },
        ],
        highlights:[
            {
                id: 1,
                text: "Build Browser based Apps"
            },
            {
                id: 2,
                text: "Logically challenging Programs"
            },
            {
                id: 4,
                text: "Two evaluation checkpoints"
            },
            {
                id: 5,
                text: "Interactive Live Classes"
            },
            {
                id: 6,
                text: "Structured and Comprehensive Curriculum"
            },    
            {
                id: 7,
                text: "Weekly Progress Report & Alerts"
            },
            {
                id: 8,
                text: "Homework Help"
            },
            {
                id: 9,
                text: "Individual Course Certification"
            },
            {
                id: 10,
                text: "Highly Qualified Instructors"
            },
            {
                id: 11,
                text: "Supportive Online Student Community"
            },
            {
                id: 12,
                text: "Hands-on, Project Based Learning"
            },    
            {
                id: 13,
                text: "Start from Scratch"
            },
            {
                id: 14,
                text: "Quiz Mania with the peers"
            },
            {
                id: 15,
                text: "Tantalizing tasks and puzzles"
            },
            {
                id: 16,
                text: "Astute Assessments"
            },
            {
                id: 17,
                text: "Summarized pre-written materials"
            },
            {
                id: 18,
                text: "Final Capstone Project"
            },    
        ],
        pre: 'Have basic knowledge of the programming language '
    },
]

export default courses