import React,{useEffect} from 'react'
import './Header.css'
import {LinkContainer} from 'react-router-bootstrap'
import {HashLink} from 'react-router-hash-link'
import {Link} from "react-scroll"
import {Navbar, Nav} from 'react-bootstrap'
import logo from '../../assets/images/logo.png'

const Header = () => {

    return (
        <nav>
            <Navbar fixed="top" collapseOnSelect expand="lg" >
            <LinkContainer to='/'>
               <Navbar.Brand className="nav-brand">
                  <img src={logo} alt="logoBrand" className="nav-brandLogo" />
               </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="ml-auto">
                <LinkContainer  to='/'>
                   <Nav.Link active className="nav-link" >Home</Nav.Link>
                </LinkContainer>
                     <LinkContainer to="/courses" >
                        <Nav.Link active className="nav-link" >Courses</Nav.Link>
                     </LinkContainer>
                <LinkContainer to='/about'>
                   <Nav.Link className="nav-link">About</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/faq'>
                   <Nav.Link className="nav-link">FAQ</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/contact'>
                   <Nav.Link className="nav-link">Contact</Nav.Link>
                </LinkContainer>
                </Nav>
            </Navbar.Collapse>
            </Navbar>
        </nav>
    )
}

export default Header
