import './App.css';
import HomeScreen from './screens/HomeScreen'
import AboutScreen from './screens/AboutScreen'
import ContactScreen from './screens/ContactScreen'
import FaqScreen from './screens/FaqScreen'
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import Courses from './screens/sections/HomeScreenSections/Courses/Courses'
import CourseScreen from './screens/CourseScreen/CourseScreen';
import PrivacyPolicy from './screens/PrivacyPolicy/PrivacyPolicy';
import {BrowserRouter as Router, Route} from 'react-router-dom'


import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
        <Header />
          <ScrollToTop>
              <Route path='/' component={HomeScreen} exact />
              <Route path='/about' component={AboutScreen} />
              <Route path='/contact' component={ContactScreen} />
              <Route path='/faq' component={FaqScreen} />
              <Route path="/courses" component={Courses} />
              <Route path='/course/:id' component={CourseScreen} />
              <Route path='/privacy' component={PrivacyPolicy} />
          </ScrollToTop>
        <Footer />
    </Router>
  );
}

export default App;
