import React from 'react'
import styles from './Testimonial.module.css' 

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import reviews from '../../../../data/review'
SwiperCore.use([Scrollbar, A11y, Autoplay,Navigation]);

const Testimonial = () => {
    return (
        <div className={styles.testimonial}>
            <h1>Hear from our <span>CodeVirtuans</span>!</h1>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{delay: 3500}}
                navigation={true}
                >
                
                {
                    reviews.map(element => {
                        return(
                            <SwiperSlide className={styles.swiper}>
                                <div className={styles.testimonialCard}>
                                    <div>
                                        <div className={styles.circle}>
                                            <img src={element.img} alt="" />
                                        </div>
                                        <p className={styles.quote}><i class="fas fa-quote-left"></i></p>
                                        <p>{element.desc}</p>
                                        <h4>{element.name}</h4>
                                        <h6>{element.loc}</h6>
                                    </div>
                                </div>    
                            </SwiperSlide>
                        )
                    })
                }
                
                ...
            </Swiper>
        </div>
    )
}

export default Testimonial
