import React from 'react'
import './PrivacyPolicy.css'
import {Container} from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <div className="pp-header">
                <Container>
                    <h1>CodeVirtua Privacy Principles</h1>
                </Container>
            </div>
            <div className="pp-body">
                <Container>
                    <h2>Our Privacy Policy is fueled by our commitment to the following Privacy Principles:</h2>
                    <ol>
                        <li>We’re deeply committed to creating a safe and secure online environment for you.</li>
                        <li>
                            We do not sell your personal information to third parties. We established ourselves as a not-
                            for-profit organization so that our mission of education and your trust will not be in conflict
                            with a for-profit motive.
                        </li>
                        <li>
                            We strive to provide you with access to and control over the information you give us, and we
                            take the protection of your information very seriously.
                        </li>
                        <li>
                            We take extra precautions for our younger learners under the age of 13, including restricting
                            child accounts to automatically block features that would allow a child to post or disclose
                            personal information.
                        </li>
                        <li>
                            We do not display advertising on CodeVirtua. Our mission is to provide you with a better
                            learning experience, not to sell you products.
                        </li>
                    </ol>

                    <h2>About CodeVirtua and our Privacy Policy</h2>
                    <p>
                        CodeVirtua (“CodeVirtua”, “us”, or “we”) feels strongly about protecting your privacy. We
                        understand how important privacy is to you, and we are committed to creating a safe and secure
                        environment for learners of all ages. This Privacy Policy applies to codevirtua.com, and online
                        services (collectively, our “Service”), which are all owned and operated by CodeVirtua. This
                        Privacy Policy describes how CodeVirtua collects and uses the information you provide on our
                        Service, and describes the choices available to you regarding our use of your personal
                        information and how you can access and update this information.
                    </p>
                    <p>
                        We use the data we collect to provide you with a better experience and fulfill our mission of
                        providing a free, world-class education for anyone, anywhere. We established ourselves as a not-
                        for-profit organization so that a for-profit motive will not interfere with our mission of providing
                        a trusted educational resource.
                    </p>
                    <p>
                        Our Privacy Policy describes what information we gather from our members and others who visit and use our Service, how we use that information, and what we do to protect it. By visiting or using our Service, you consent to the information handling practices described in this Privacy Policy.
                    </p>
                    <p>
                        Your use of our Service and any information you provide on our Service are subject to the terms of this Privacy Policy, which is incorporated into and subject to our Terms of Service. Any capitalized terms used but not defined within this Privacy Policy have the meaning given to them in our Terms of Service.
                    </p>

                    <h2>1. What information do we collect?</h2>
                    <b>We take the information you provide to us very seriously, and we strive to put you in control of decisions around your information.</b>
                    <p>
                    You can use many features of the Service without registering for an account or providing any other personal information. If you create an account on the CodeVirtua Service, or communicate with CodeVirtua, you may provide to CodeVirtua what is generally called “Personal Information” or “Personal Data,” such as your full name, birthdate, email address or other information that can be used to identify you. Information which cannot be used to identify you as an individual is “de-identified” or non-personal information.
                    </p>
                    <p>We collect information, including personal information, in a variety of ways which may vary according to your use of the Service and your account settings. The categories of personal information we collect may include:</p>
                    <ul>
                        <li>Contact and profile information</li>
                        <li>Account and authentication information from third-party integrated services</li>
                        <li>Location information</li>
                        <li>Information about your browser or device</li>
                        <li>Donation and applicant data</li>
                        <li>Non-personal information which may be linked to your Personal Information</li>
                    </ul>
                    <p>Below, we describe examples of the categories of information we collect from and about you when you use our Service:</p>
                    <h4><b>Information you provide to us and information we receive about you from others</b></h4>
                    <h4><b>Contact and profile information.</b></h4>
                    <p>When you create an account on the CodeVirtua Service, or communicate with us, we may collect personal information including your name, email address, and birthdate. After you register, you may also choose to provide additional information in your profile, such as your nickname, location (city), and other personal or demographic information. In addition, we may ask you for personal information at other times, such as when you contact our technical support team, send us an email, complete a user survey, or otherwise communicate with CodeVirtua.</p>
                    <h4><b>Information from Integrated Sign-On Services.</b></h4>
                    <p>If you decide to register through or otherwise grant access to a third-party social networking or integrated service (what we call an “Integrated Service”), such as Facebook, Google or similar single sign-on service, CodeVirtua may also collect Personal Information that is already associated with your Integrated Service account. You may also have the option of sharing additional information with CodeVirtua through an Integrated Service, as controlled through your settings on that Integrated Service. If you choose to provide such information, during registration or otherwise, CodeVirtua will treat the information as Personal Information and will use it in the ways described in this Privacy Policy.</p>
                    <h4>Information obtained from other users.</h4>
                    <p>We make available certain features on our Service that allow other users to provide us information about you. For example, a parent or teacher may provide information relating to a Child User, such as when a parent registers an account for their Child and provide us with certain information about the Child, such as user name and age.</p>
                    <h4>Information provided by partners and other sources.</h4>
                    <p>We may also receive information from third party organizations with which we partner to provide educational services. If we combine or associate information from other sources with Personal Information that we collect through our Service, we will treat the combined information as Personal Information in accordance with this Privacy Policy.</p>
                    <h4>Information we collect to enable your participation in special programs or features.</h4>
                    <p>From time to time, CodeVirtua may feature a special program or activity we offer in partnership with a third party. If you participate in these special programs, CodeVirtua may collect personal information from you to facilitate those programs. For example, LearnStorm participants may be asked to provide information related to event logistics and prize awarding and processing (e.g., contact information, dietary restrictions, shirt sizes, etc.), and College Board program participants may provide information regarding study habits and test scores (e.g., the number of hours studied, modules or tests taken, scores earned, etc.).</p>
                    <h4>
                    Information about how you access and use the Service
                    </h4>
                    <h5>Information about your use of our Service.</h5>
                    <p>We may collect usage information about your use of our Service, such as the number of problems you have attempted, the number of videos you have viewed, and the amount of time spent to complete a problem. This enables us to better tailor educational experiences that are most appropriate for you.</p>
                    <h5>Location information.</h5>
                    <p>We may collect and use information about your location (such as your country) or infer your approximate location based on your IP address in order to provide you with tailored educational experiences for your region, but we don't collect the precise relocation of you or your device. You may be able to change the settings on your computer or mobile device to prevent it from providing us with any location information.</p>

                    <h4>Other information</h4>
                    <h4>Usage and device information.</h4>
                    <p>
                    To provide a personalized learning and high-quality experience for our users, we may use various technologies that automatically record certain technical information from your browser or device when you visit our website, read our emails, use our Service or otherwise engage with us. This information is typically collected through a variety of tracking technologies, including cookies, web beacons, Flash objects, log files, and similar technology (collectively, “tracking technologies”). These tracking technologies cookies collect information about how you use the Service (e.g., the pages you view, the links you click, and other actions you take on the Service), information about your browser and online usage patterns (e.g., Internet Protocol (“IP”) address, browser type, browser language, referring / exit pages and URLs, pages viewed, whether you opened an email, links clicked), and information about the device(s) you use to access the Service (e.g., mobile device identifier, mobile carrier, device type, model and manufacturer, mobile device operating system brand and model, and whether you access the Service from multiple devices). We may collect analytics data, or use third-party analytics tools such as Google Analytics, to help us measure traffic and usage trends for the Sites and to understand more about the demographics of our users. You can learn more about Google's practices at http://www.google.com/policies/privacy/partners, and view its currently available opt-out options at https://tools.google.com/dlpage/gaoptout. We may also work with third party partners to employ technologies, including the application of statistical modeling tools, which permit us to recognize and contact you across multiple devices. Although we do our best to honor the privacy preferences of our users, we are unable to respond to Do Not Track signals set by your browser at this time.
                    </p>
                    <p>
                    We use or may use the data collected through tracking technologies to better display our website, to save you time, to provide better technical support, for promotional purposes, and to track website usage. For example, cookies help us to:
                    </p>
                    <ol>
                        <li>Keep track of whether you are signed in or have previously signed in so that we can display all the features that are available to you.</li>
                        <li>Remember your settings on the pages you visit, so that we can display your preferred content the next time you visit.</li>
                        <li>Customize the function and appearance of the pages you visit based on information relating to your account; for example, in order to default you to a particular study level, or to remember customized settings for a report.</li>
                        <li>Track website usage for various purposes including sales, marketing, and billing.</li>
                    </ol>
                    <p>Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, some features and services (particularly those that require you to sign-in) may not function properly if your cookies are disabled. You may also set your email options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether you have accessed our email and performed certain functions with it.</p>
                    <p>Deleting cookies does not delete Local Storage Objects (LSOs) such as Flash objects and HTML5. You can learn more about Flash objects - including how to manage privacy and storage settings for Flash cookies – on Adobe’s website or by clicking here. Various browsers may offer their own management tools for removing HTML5 LSOs. Please consult your browser’s “Help” function to learn more. If you choose to delete Flash objects from our sites, then you may not be able to access and use all or part of the sites or benefit from the information and services offered.</p>
                    <p>We and our third-party partners may also use cookies and tracking technologies for advertising purposes. For more information about tracking technologies, please see Section 5 “Third Parties and Online Advertising” below.</p>


                    <h2>2. How we use the information we collect</h2>
                    <h4>CodeVirtua uses this information in the following ways:</h4>
                    <h5>To provide and enhance our Service.</h5>
                    <p>CodeVirtua uses the information you provide or that we collect to operate, maintain, enhance, and provide all of the features of our Service.</p>

                    <h5>To personalize your experience.</h5>
                    <p>We use the information to personalize your experience while using the Service, including on various devices you may use to access the Service. For instance, CodeVirtua remembers your recent activity so we can recommend the most appropriate content for you on your next visit and provide personalized learning experiences.</p>

                    <h5>To communicate with you.</h5>
                    <p>We use your information to communicate with you about your account and respond to inquiries. We may also use your Personal Information to provide you with information about CodeVirtua’s features, services and other offerings that may be of interest to you.</p>

                    <h5>To understand, improve, and develop our Service.</h5>
                    <p>CodeVirtua uses all of the information that you provide or that we collect from users to understand and analyze the usage trends, learning behaviors, and preferences of our users, to improve the way the Service works and looks, and to create new features and functionality.</p>
                    <p>We may also use information to maintain, develop, support and improve our Service and other educational products and services.</p>

                    <h5>To enable your participation in CodeVirtua partnership arrangements.</h5>
                    <p>We use your information to enable your participation in programs or features we may offer in partnership with third parties, to the extent you wish to participate in such programs.</p>

                    <p>For personal data subject to the European Union General Data Processing Regulations (“GDPR”), we rely on several legal bases to process the data. These legal bases include where:</p>

                    <ul>
                        <li>The processing is necessary to perform our contractual obligations in our Terms of Service or other contracts with you (such as to provide you the Service as described in our Terms of Service);</li>
                        <li>You have given your prior consent, which you may withdraw at any time (such as for marketing purposes or other purposes we obtain your consent for from time to time);</li>
                        <li>The processing is necessary to comply with a legal obligation, a court order or to exercise or defend legal claims;</li>
                        <li>The processing is necessary for the purposes of our legitimate interests, such as in improving, personalizing, and developing the Service, marketing the Service, such as new features or products that may be of interest, and promoting safety and security as described above.</li>
                    </ul>
                    <p>
                        If you have any questions about or would like further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided below.
                    </p>
                    <h2>3. How we share or transfer data</h2>
                    <p>
                    CodeVirtua takes great care to protect the information you provide us. We do not rent or sell Personal Information that we collect from users with third parties.
                    </p>
                    <p>
                    CodeVirtua will disclose personal information when you post content which is visible to others on the Service, when you consent or instruct us to share your personal information with third parties, or when we have a legitimate business or legal need to share your information. We may also disclose anonymous or aggregate information that does not reasonably identify you as an individual. To learn more, see additional description below.
                    </p>

                    <h2>When information is visible to others on the Service by default.</h2>
                    <p>
                        Certain features and functions of the Service shares or makes your information accessible to others. As with most online services, once you make your Personal Information available to others online, it may be collected and used by the recipients or any other website visitor without restriction. You may wish to use a nickname or alias in your profile if you do not want your personal information shared with others in this way. For example, your personal information may be visible to others in the following circumstances:
                    </p>
                    <ul>
                        <li>Information you post to public spaces of the Service. When you post a question or answer to the Service, your profile, which could include your name, short biography, and city-level location, as well as the content of your posted comment, is visible to others on the Service.</li>
                        <li>Connecting with a Mentor. When your account is connected to a Mentor on the Service, the Mentor will be able to see your profile information and your activities on the Service, such as which videos you have watched, the questions and responses you post, and other usage information. Coaches may also be able to see your name (not only your username) as well as your email address, if you include that information in your account profile.</li>
                    </ul>

                    <h5>With your consent or according to your instructions.</h5>
                    <p>
                        You may provide consent or authorization to share your personal information with third party applications or services in several ways. Please note that these third parties are not governed by CodeVirtua or the terms of this Privacy Policy. We recommend you review the privacy policy of any third party application or service before authorizing access to your CodeVirtua account information.
                    </p>
                    <ul>
                        <li>We may share data with social networks at your request. Some of our users choose to connect their CodeVirtua account with social networks. This functionality is entirely optional. If you have explicitly agreed that we may do so, CodeVirtua may disclose your Personal Information through a social network or similar service (like Facebook or Google). To control the information that you share, you have the option of modifying your CodeVirtua or social network settings or disconnecting your social network account from your CodeVirtua account.</li>
                        <li>We may share data with third-party applications that you authorize. Third-party application developers and service providers (commonly known as “App Developers”) may build complementary services for our platform, such as a mobile application for visually-impaired learners to access our resources, or may use our CodeVirtua content to build unaffiliated applications and services. If you connect your CodeVirtua account to an application or service, or approve access to your CodeVirtua account by a third party application or service, you consent to CodeVirtua sharing your information with that third party.</li>
                        <li>We may share information with your consent. CodeVirtua does not share your Personal Information with third-party organizations for their marketing or promotional use without your consent. In some instances, you may be able to grant us permission to share your Personal Information with authorized partners, not-for-profit organizations, and other entities that are not affiliated with CodeVirtua. In these cases, we will only provide to these third parties the information you have authorized or asked us to share to these third parties. You may also choose to share content with others by email, or by posting CodeVirtua content to social media sites such as Facebook or Twitter. These third parties may use your information as described in their own privacy policies.</li>
                    </ul>

                    <h5>We may share anonymous or aggregate data with others.</h5>

                    <p>We may use data which has been de-identified and/or aggregated for product development, research, analytics and other purposes, including for the purpose of analyzing, improving, or marketing the CodeVirtua Services. In certain occasions, CodeVirtua may share this data with business partners to improve our services or offerings. If we disclose information to authorized business partners to conduct research on online education or assist in understanding the usage, viewing, and demographic patterns for certain programs, content, services, promotions, and/or functionality on our Service, such data will be aggregated and/or anonymised to reasonably avoid identification of a specific individual.</p>

                    <h5>Other instances where we may share or disclose information for legal or business purposes.</h5>
                    <ul>
                        <li>We will share data with employees and service providers. CodeVirtua may share information with our employees and trusted vendors, third party service providers and other individuals to provide services or products for us or on our behalf, which could include analytics, hosting, marketing and similar services. When we share personal information with third party service providers or vendors, these third parties are contractually obligated to maintain the security and confidentiality of that personal information.</li>
                        <li>We may share data in the context of a change of business, including a merger or acquisition. In the event that CodeVirtua is involved in a merger, acquisition, bankruptcy, change of control, or any form of sale of some or all of our assets, your personal information may be transferred or disclosed in connection with such business transaction. We will require the new owner to continue to honor the terms provided in this Privacy Policy, or we will provide you with a notice and an opportunity to opt-out of the transfer by deleting your information before the transfer occurs.</li>
                        <li>Other instances. CodeVirtua may release Personal Information if we have a good faith belief that access, use, preservation, or disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal process, or enforceable governmental request; (b) enforce applicable Terms of Service, including investigation of potential violations thereof; (c) investigate and defend ourselves against any third-party claims or allegations; (d) detect, prevent or otherwise address fraud, security or technical issues; (e) protect the rights, property, or personal safety of CodeVirtua, our users, or the public; or (f) as required or permitted by law.</li>
                    </ul>

                    <h2>4. Marketing and Sponsored Content</h2>
                    <h6>Messages from CodeVirtua</h6>
                    <p>
                    We may, from time to time, send you email regarding our products and services, or other third party products and services we think you may enjoy. For example, if we partner with a not-for-profit organization running a contest in your region we may send you an email notifying you of the partnership or contest. We will not share your information with the third party unless you opt-in to participate in the partnership or contest, and if so, the information we would share would be limited to that needed to facilitate your participation in the contest and enable you to redeem your prize. You can unsubscribe from these mailings at any time. See Section 6 below.
                    </p>

                    <h6>Sponsored Content</h6>
                    <p>
                    CodeVirtua does not display any third party advertisements on the Service. From time to time, we permit third parties to sponsor content displayed on our Service. For example, for-profit organizations may wish to sponsor all content related to a particular educational topic. Sponsored content will always be labeled (e.g., “Sponsored by ___”). CodeVirtua does not share any of our users' Personal Information with these sponsors without explicit consent, and these sponsors do not have the ability to track or collect information about our site visitors or users. 

                    </p>

                    <p>
                    Please note: From time to time, we may display YouTube video content created by third parties and not by CodeVirtua. While CodeVirtua-created video content does not display video advertisements, third party content may include advertising which we cannot control.
                    </p>


                    <h2>5. Third Parties and Online Advertising</h2>
                    <h6>Interest Based Advertising</h6>
                    <p>
                    CodeVirtua does not display any targeted advertising on our Service. However, we participate in interest-based advertising and use third party advertising companies to serve you targeted advertisements on other websites, apps or services, including on Facebook and other social networks, or on other devices you may use. These third party ad networks use tracking technologies to recognize your browser or device and to collect information about your visit to our Service to provide you customized content, advertising and commercial messages which may be more relevant to your interests, as well as to provide advertising-related services such as reporting, attribution, analytics and market research.
                    </p>
                    <p>
                    Please note that we use our best efforts and take multiple steps to avoid the collection of information for targeted advertising purposes when we believe the Service is being used by students or children under 13. For example, we take steps to inhibit these third party advertising networks from collecting information for targeted advertising purposes on webpages with child-directed content or when a student or child user logs into the Service.
                    </p>
                    <p>
                    To learn about interest-based advertising and how you may be able to opt-out of some of this advertising, you may wish to visit the Network Advertising Initiative’s online resources, at https://optout.networkadvertising.org/?c=1, and/or the DAA's resources at http://www.aboutads.info/choices. You may also manage certain advertising cookies by visiting the EU-based Your Online Choices at http://www.youronlinechoices.eu/. You may also be able to limit interest-based advertising through the settings on your mobile device by selecting “limit ad tracking” (iOS) or “opt-out of interest based ads” (Android). You may also be able to opt-out of some – but not all – interest-based ads served by mobile ad networks by visiting http://youradchoices.com/appchoices and downloading the mobile AppChoices app.
                    </p>
                    <p>
                    Please note that opting-out of receiving interest-based advertising through the NAI's and DAA's online resources will only opt-out a user from receiving interest-based ads on that specific browser or device, but the user may still receive interest-based ads on his or her other devices. You must perform the opt-out on each browser or device you use.
                    </p>

                    <h6>YouTube</h6>
                    <p>
                    CodeVirtua uses YouTube to display video content on the Service. YouTube incorporates tracking technologies, which may be present in the YouTube videos embedded on the Service, which may collect information from your browser when you view the video on the Service, including device identifiers and/or cookie IDs. This information is collected directly and automatically by YouTube and its partners, and CodeVirtua does not participate in these data transmissions. CodeVirtua does not provide any Personal Information, such as user names, to YouTube.
                    </p>

                    <p>
                    CodeVirtua has taken steps to minimize this tracking activity, to the extent we have controls over a third-party's practices. We have turned on “Privacy-Enhanced Mode” for YouTube videos displayed on the Service. We also utilize Google's “child-directed treatment” tags to alert Google that these YouTube videos may be embedded on webpages visited by children or students. You can learn more about Google’s practices at http://www.google.com/policies/privacy/partners. You may also be able to control ad tracking through YouTube by visiting the Google Ad Settings page, currently available at: https://adssettings.google.com.
                    </p>

                    <h2>6. Your choice and options relating to data collection and use</h2>
                    <p>
                    At CodeVirtua, we use Personal Information as needed for the purposes for which it was collected or where you have consented to our use of your Personal Information. We take reasonable steps to ensure that the Personal Information we store and use is accurate, complete, and up-to-date. If you discover that Personal Information or other data pertaining to you is inaccurate, incomplete, or out-of-date, please update your account information or contact us as outlined below.
                    </p>
                    
                    <h5>You can choose to not provide us with Personal Information</h5>
                    <p>
                    You may always decline to provide your Personal Information with CodeVirtua. Registration is not required to access most of our online resources. If you decline to register, however, CodeVirtua will not be able to provide to you certain features and functionalities found on our Service. You may later enable or access those features by providing CodeVirtua with the necessary Personal Information.
                    </p>
                    <p>
                    You can chose to provide only information which does not reasonably identify you to others. We recommend you select a username which is not related to your actual name, for example. You can also decline to provide any optional data in your public profile.
                    </p>

                    <h5>You can disconnect us from Integrated Services.</h5>
                    <p>You may revoke CodeVirtua's access to your account on any Integrated Service, such as Google or Facebook, at any time by updating the appropriate settings in the account preferences of the respective Integrated Service. You should check your privacy settings on each Integrated Service to understand and change the information sent to us through each Integrated Service. Please review each Integrated Service's terms of use and privacy policies carefully before using their services and connecting to our Service.</p>

                    <h5>You can unsubscribe from email communications.</h5>
                    <p>
                    CodeVirtua may, from time to time, send you email regarding our products and services, or products and services we think you may enjoy. Only CodeVirtua (or our vendors or service providers operating on our behalf) will send you these emails. You can choose not to receive these emails by clicking the unsubscribe link in any e-mail. Please note that you are not permitted to unsubscribe or opt-out of non-promotional messages regarding your account, such as account verification, changes or updates to features of the Service, or technical or security notices.
                    </p>

                    <h5>California Children's Privacy Rights</h5>
                    <p>
                    If you are under the age of 18 residing in California, you are entitled to request removal of content or information you have posted publicly on our Service. To delete your entire account and remove all of your information displayed publicly on the Service, log into your account and select “delete your account” through Settings. To request deletion or de-identification of a specific question, answer or other post displayed publicly on the Service, please email us at privacy@codevirtua.com for assistance. You must be able to identify the particular post you wish to be deleted or de-identified so that we can locate it on the Service. Please note that removal of your content or information does not ensure complete or comprehensive removal, as there may be de-identified or recoverable elements of your content or information on our servers in some form. Additionally, we will not remove content or information that we may be required to retain under applicable federal and state laws.
                    </p>

                    <h2>7. How to access, update, or delete your personal information</h2>
                    <h5>How to access or update your information.</h5>
                    <p>
                    We want you to have access to your information, so that you can help keep it as accurate as possible. If you register and provide CodeVirtua with Personal Information, you may update, correct, or delete your account and information at any time by reviewing your profile information and preferences on your account settings page.
                    </p>
                    <p>
                    Parents can modify or delete Child User accounts. Parents who request to modify or delete School User accounts may be directed to contact the School.
                    </p>
                    <p>
                    CodeVirtua will work with Schools to provide parents access to information in School User accounts at the request and direction of the School. If you experience any difficulties in this process, please contact us as described below.
                    </p>

                    <h5>How to delete your information.</h5>
                    <p>
                    To request deletion of your personal information at any time, please log into your account and select “Delete your Account” through Settings. You can learn more in our Help Center, here. Please note that your information will be retained in backup for up to one week. If you log back into your account within 24 hours, your account will not be deleted and you will need to go through the deletion process again.
                    </p>
                    <p>
                    We may not be able to delete data in all instances, such as information retained in technical support logs and other business records. We will not be required to delete any information which has been de-identified or disassociated with personal identifiers such that it can no longer be used to reasonably identify a particular individual.
                    </p>
                    <p>
                    Unless we receive a deletion request, we will retain your information for as long as your account is active or as is reasonably useful for operational purposes. For example, we may retain certain data as necessary to prevent fraud or future abuse, for recordkeeping or other legitimate business purposes, or if required by law. We may also retain information which has been de-identified or aggregated such that it can no longer reasonably identify a particular individual. All retained personal information will remain subject to the terms of this Privacy Policy.
                    </p>

                    <h2>8. Our approach to Data Security</h2>
                    <h5>Data security is important to you, and to us.</h5>
                    <p>To protect your privacy and security, we take reasonable steps to verify your identity before granting your account access or making corrections to your information. For example, we may ask you to provide certain Personal Information to confirm your identity, and we may require that you create and use a password to access certain parts of our Service. You should create and maintain a strong password to help ensure the security of your account.</p>

                    <h5>We try to ensure that our Service and information sent to us are safe, but no security measures are perfect.</h5>
                    <p>
                    CodeVirtua uses certain physical, managerial, and technical safeguards designed to preserve the integrity and security of your Personal Information and other information we maintain in connection with our Service. We cannot, however, ensure or warrant the security of any or all of the information you transmit to CodeVirtua, and you do so at your own risk. Once we receive your transmission of information, CodeVirtua makes commercially reasonable efforts to ensure the security of our systems. When you enter sensitive information, we encrypt the transmission of that information using secure socket layer technology (SSL) or similar technologies. However, please note that this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and will notify you of the security incident in accordance with applicable laws and regulations.
                    </p>
                    
                    <h2>10. How does CodeVirtua protect Children's privacy?</h2>
                    <p>
                    CodeVirtua does not permit children under the age of 13 (a “Child” or “Children”) to create an account without the consent and at the direction of a Parent or School. Please contact us at privacy@codevirtua.com if you believe we have inadvertently collected information from a child under 13 without parental consent so that we may delete the information as soon as possible.
                    </p>
                    <p>
                    Children under 13 may create an account with the parent's consent. Please see the Children's Privacy Policy to learn more about how CodeVirtua collects, uses and shares information associated with Child accounts.
                    </p>

                    <h2>11. International Visitors</h2>
                    <p>
                    Our Service is operated and managed on servers located within the United States. If you choose to use our Service from the European Union or other regions of the world with laws governing data collection and use that differ from United States law, then you acknowledge that CodeVirtua will transfer your personal information to the United States for the purpose of performing the Service according to our contract (e.g., our Terms of Service) and for any other purpose for which you provide explicit, informed consent.
                    </p>

                    <h2>12. European Union Data Protection</h2>
                    <p>Residents in the European Union are entitled to certain rights with respect to personal information that we hold about them:</p>

                    <ul>
                        <li>Right of access and portability. The right to obtain access to your personal information, along with certain related information, and to receive that information in a commonly used format and to have it transferred to another data controller;</li>
                        <li>Right to rectification. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete;</li>
                        <li>Right to erasure. The right to obtain the erasure of your personal information without undue delay in certain circumstances, such as where the personal information is no longer necessary in relation to the purposes for which it was collected or processed;</li>
                        <li>Right to restriction. The right to obtain the restriction of the processing undertaken by us on your personal information in certain circumstances, such as where the accuracy of the personal information is contested by you, for a period enabling us to verify the accuracy of that personal information; and</li>
                        <li>Right to object. The right to object, on grounds relating to your particular situation, to the processing of your personal information, and to object to processing of your personal information for direct marketing purposes, to the extent it is related to such direct marketing.</li>
                    </ul>
                    <p>
                    You may also have the right to make a complaint to the relevant Supervisory Authority. A list of Supervisory Authorities is available here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm. If you need further assistance regarding your rights, please contact us using the contact information provided below and we will consider your request in accordance with applicable law. In some cases our ability to uphold these rights for you may depend upon our obligations to process personal information for security, safety, fraud prevention reasons, compliance with regulatory or legal requirements, or because processing is necessary to deliver the services you have requested. Where this is the case, we will inform you of specific details in response to your request.
                    </p>


                    <h2>13. Links to other sites</h2>
                    <p>
                    The Services may link to and may be linked by websites operated by other entities or individuals. Some of these websites, such as the CodeVirtua Facebook page, may be co-branded with our name or logo. This Privacy Policy does not apply to, and we cannot always control the activities of, such other third-party websites. You should consult the respective privacy policies of those third-party websites.
                    </p>

                    <h2>14. Changes and updates to this Privacy Policy</h2>
                    <p>CodeVirtua may modify or revise this Privacy Policy from time to time. CodeVirtua will notify users of any changes to our Privacy Policy by posting the revised Privacy Policy with an updated date of revision on our Service. If we change this Policy in a material manner, we will do our best to notify you of the changes by posting a notice on our website. Your continued use of the Services following the effective date of such update constitutes your acceptance of the revised Policy.</p>

                    <h2>15. Contacting CodeVirtua</h2>
                    <h6>Please contact CodeVirtua with any questions or comments.</h6>
                    <h6>By email: privacy@codevirtua.com</h6>
                    <h6>By mail: YOUR REGISTERED ADDRESS/ OR PRESENT ADDRESS</h6>

                    <h5>CodeVirtua Children's Privacy Policy</h5>
                    <h5>CodeVirtua is committed to children’s privacy.</h5>
                    <p>
                    Protecting the privacy of children is especially important to CodeVirtua. For that reason, we created certain features designed to help protect personal information relating to children who are less than 13 years of age (“Child Users”). CodeVirtua does not knowingly permit Child Users to register directly for our Service without consent of a Parent. If CodeVirtua learns that Personal Information of a Child User has been collected on our Service without parental consent, then CodeVirtua will take appropriate steps to delete this information. If you are a parent or guardian (“Parent”) and discover that your child under the age of 13 has a registered account with our Service without your consent, please alert CodeVirtua at privacy@codevirtua.com and request that we delete that child’s personal information from our systems.
                    </p>

                    <h6>How children may register for and use our Service.</h6>
                    <p>Child Users can sign up for a CodeVirtua account several ways.</p>

                    <h5>1. When a Child User signs up for a CodeVirtua Account we request Parental consent.</h5>
                    <p>
                    When a Child User registers for our Service, we request a birthdate, username, password, and a parent's email address so that we can email the Child User's Parent in order to seek consent for the Child to use the Service. CodeVirtua does not ask the Child User for any more information than is necessary to provide the Service to the Child User or to seek parental consent. The Child User is permitted to use the Service for seven (7) days through a Restricted Account while we notify the Parent for consent. If we do not receive Parental consent within 7 days, the Child User's Restricted Account is closed, and the Child's personal information is deleted from our systems.
                    </p>

                    <h5>Methods for providing Parental consent.</h5>
                    <p>
                    Parents may provide consent for a Child User to use the Service through a Restricted Account (explained below) by responding affirmatively to an email sent by CodeVirtua to the Parent's email address provided by the Child User during registration. If we do not receive consent from the Parent within seven (7) days, the Child User's account will be closed and the Child's personal information is deleted from our systems. Restricted Accounts may access and use certain features of the Service in a limited manner.
                    </p>
                    <p>
                    To enable additional functionality for the Child User account and to enable full access and control over the Child User's account activity, the Parent must create a Parent Account on the Service and connect to the Child User's account by clicking on the account creation link in the Parental consent notice. Parent Accounts control the settings and functionality of the Child account. The Parent can maintain the Child's Restricted Account, and/or can enable additional functionality and data sharing features, if desired. Parents can revoke a Child User's permissions to use the Service at any time by deleting the account.
                    </p>

                    <h2>4. Restricted Accounts for Child Users.</h2>
                    <p>
                    CodeVirtua attempts to restrict a Child User's access to certain features that could result in disclosure of the Child's personal information. A Restricted Account limits sharing and displaying private information about the Child User in various ways.
                    </p>

                    <p>For example, a Child User with a Restricted Account cannot:</p>
                    <ul>
                        <li>Display personal information other than a username. Restricted Accounts contain the Child's username and do not include other personal information or profile data. Because the username may be displayed on the Service, we strongly recommend selecting a username which does not relate to or identify the real name of the Child. The Child username can be selected or revised by the Parent by creating a Parent Account.</li>
                        <li>Add or edit personal information associated with a Restricted Account</li>
                        <li>Post to public discussion forums available on the website.</li>
                        <li>See or access links to third-party sites on the Service</li>
                        <li>Receive emails from CodeVirtua (all emails are sent to the Parent email on file)</li>
                        <li>Communicate with or share personal information with Mentors other than a username and Service usage activity (i.e., lessons completed, etc.). Similarly, Mentors cannot communicate with a Child User with a Restricted Account except to assign content for the Child User to view through the class.</li>
                    </ul>
                    <p>
                    A Parent who creates a Parent Account can manage and control additional features and functionality. For example, Parents can create or revise a Child's username; add or revise profile information; restrict or enable the Child User to add a Coach using a class code. Please see “How are child accounts different from regular accounts” in the Help Center to learn more.
                    </p>


                    <h2>5. What information do we collect from a Child User?</h2>
                    <p>We collect a username, birthdate, and Parent's email address when a Child User registers for the Service, as well as any other personal information a Parent adds to a Child User's account, as described above. We collect information about the Child User's use of the Service (lessons completed, etc.) as well as content the Child posts to the Service if such functionality is enabled by the Parent. We also collect usage and device information, as described in our Privacy Policy. We use this information to provide the Service to the Child User and for the purposes described in in our Privacy Policy's section “How we use the information we collect.” We use the Parent's email address to communicate messages about the account.</p>

                    <h2>6. How do we disclose information relating to a Child User?</h2>
                    <p>
                    As described above, Restricted Accounts have limited data sharing capabilities. A username and progress information, along with any personal information the Parent added to the Child's profile, is shared with a Coach or teacher added to the Child User's account, though a Parent Account may restrict the Child User from adding Coaches to the account. Parents may also enable additional data sharing features through a Parent Account, for example, by adding profile information and permitting the profile to be shared with Coaches and other users, or enabling the Child User to post content (e.g., questions and answers) to the Service. Parents that enable content-posting features should be aware that a Child User could post personally identifiable information in free-form content on the Service, and should monitor the Child User's posts and delete personal information if needed. We may also disclose personal information of Child Users for business purposes, as described in the Privacy Policy section “Other instances where we may share or disclose information for legal or business purposes.” Mentioned above
                    </p>

                    <h2>7. Third party tracking and online advertising.</h2>
                    <p>
                    CodeVirtua does not display any targeted advertising on the Service. We do not disclose personal information of Child Users for direct marketing purposes or for targeted advertising purposes. While we do permit third party advertising partners to operate on our Service for the purpose of retargeting, analytics and attribution services (See our Privacy Policy section “Third Parties and Online Advertising”), we take steps to disable third party ad networks on webpages with child-directed content or when a Child User logs into a Restricted Account on the Service.
                    </p>

                    <h6>YouTube</h6>
                    <p>
                    CodeVirtua uses YouTube to display video content on the Service. YouTube incorporates tracking technologies, which may be present in the YouTube videos embedded on the Service, which may collect information from your browser when you view the video on the Service, including device identifiers and/or cookie IDs. This information is collected directly and automatically by YouTube and its partners, and CodeVirtua does not participate in these data transmissions. CodeVirtua does not provide any personal information, such as usernames, to YouTube.
                    </p>

                    <p>
                    CodeVirtua has taken steps to minimize this tracking activity, to the extent we have controls over a third-party's practices. We have turned on “Privacy-Enhanced Mode” for YouTube videos displayed on the Service. We also utilize Google's “child-directed treatment” tags to alert Google that these YouTube videos may be embedded on webpages visited by children under the age of 13. You can learn more about Google's practices at http://www.google.com/policies/privacy/partners. You may also be able to control ad tracking through YouTube by visiting the Google Ad Settings page, currently available at: https://adssettings.google.com.
                    </p>

                    <h2>8. How to access, modify and delete your Child's personal information.</h2>
                    <p>
                    As a Parent, you have the ability to access control information about your Child User in the “Your Children” section of your Parent Account. To refuse future collection or use of your child's Personal Information, you can delete your Child User's Account by following the instructions mentioned here above. Please note, you must delete your Child User’s account before you delete the Parent Account.
                    </p>
                    <p>If you do not have a Parent Account associated with your Child User's Restricted Account, you may contact us at support@codevirtua.com to access or delete the Child User's account. Please note you will need the Child User's username, and we may take steps to authenticate your identity before we can provide access to the Restricted Account.</p>

                </Container>
            </div>
        </div>
    )
}

export default PrivacyPolicy
