import React from 'react'
import { Container} from 'react-bootstrap'
import './FaqHero.css'

const FaqHero = () => {
    return (
        <div className="faq-hero">
            <Container>
               <h1>Questions? <span>Clarify </span>here.</h1>
            </Container>
        </div>
    )
}

export default FaqHero
